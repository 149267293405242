import { permissionResourcesKeys } from '../common/index'

const {
  schoolsList,
  communitiesList,
  usersList: usersListPermission,
} = permissionResourcesKeys

export const navbarItems = [
  {
    name: 'Користувачі',
    path: '/users-list',
    permission: usersListPermission,
  },
  {
    name: 'Навчальні заклади',
    path: '/schools-list',
    permission: schoolsList,
  },
  { name: 'Громади', path: '/community-list', permission: communitiesList },
  {
    name: 'Інформаційна сторінка',
    path: '/info-page',
  },
]
