import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

type CustomModalProps = {
  isOpen: boolean
  headerText: string
  children?: React.ReactNode
}

export const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  headerText,
  children,
}) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{headerText}</DialogTitle>
      {children}
    </Dialog>
  )
}
