import React, { Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { createCommunity } from '../../redux/thunk/communitiesThunk'

import { IpButton } from '../ui/IpButton'

import { formFieldTypes } from '../../types/formFields.types'
import { createCommunitFields } from '../../constants/forms/community'
import { communityRequiredFields } from '../../constants/forms/validation'
import { useValidation } from '../../hooks/validation/useValidation'
import { useFormData } from '../../hooks/useFormData'
import { TextFormField } from '../formFields/TextFormField'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { CatchErrorType } from '../../types/redux'
import { routesConfiguration } from '../../routing'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import './index.scss'

type formikValuesTypes = {
  communityName: string
  uniqueCommunityId: string
  [key: string]: string
}

const initialValues = {
  communityName: '',
  uniqueCommunityId: '',
}

const { errorCreateCommunity, successCreateCommunity } = trackEvents.communities

export const CreateCommunityForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { trackEvent } = useTracking()

  const { isLoading } = useAppSelector((state) => state.communities)

  const { formSchema } = useValidation(communityRequiredFields)

  const { isDataChanged, checkIsDataChanged, isFormValid, checkIsFormValid } =
    useFormData()

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues,
    validationSchema: formSchema,

    onSubmit: () => {
      const { communityName, uniqueCommunityId } = values
      const bodyData = { uniqueCommunityId, name: communityName }
      const payload = {
        body: bodyData,
        onSuccess: () => {
          trackEvent({ name: successCreateCommunity, payload: bodyData })
          navigate(routesConfiguration.successCreateCommunity.path, {
            state: bodyData,
          })
        },
        onError: (error: CatchErrorType) => {
          trackEvent({ name: errorCreateCommunity, payload: error })
        },
      }
      dispatch(createCommunity(payload))
    },
  })

  useEffect(() => {
    checkIsDataChanged(values, initialValues)
  }, [values, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(createCommunitFields).map(
        (fieldProps: formFieldTypes, index) => {
          return (
            <Fragment key={index}>
              {fieldProps.type === 'text' && (
                <TextFormField
                  {...fieldProps}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched[fieldProps.name]}
                  error={errors[fieldProps.name]}
                  isLoading={isLoading}
                />
              )}
            </Fragment>
          )
        }
      )}

      <MandatoryFieldNotification />

      <IpButton
        variant="contained"
        size="large"
        type="submit"
        disabled={!isFormValid || !isDataChanged || isLoading}
      >
        {isLoading ? 'Створення...' : 'Створити'}
      </IpButton>
    </form>
  )
}
