import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsUserId,
  maxLengthUserId,
  requiredUserId,
  invalidSymbolUserId,
  userIdValidation,
} = validation

export const getUserIdSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsUserId, maxLengthUserId)
    .matches(userIdValidation, invalidSymbolUserId)

  schema = isRequired ? schema.required(requiredUserId) : schema.notRequired()

  return schema
}
