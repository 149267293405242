export const alertTypes = {
  error: 'error',
  success: 'success',
}

export const permissionResourcesKeys = {
  schoolsList: 'Schools.List',
  schoolsCreate: 'Schools.Create',
  schoolsView: 'Schools.View',
  schoolsEdit: 'Schools.Edit',
  schoolsSubordinate: 'Schools.Subordinate',
  communitiesList: 'Communities.List',
  communitiesCreate: 'Communities.Create',
  communitiesView: 'Communities.View',
  communitiesEdit: 'Communities.Edit',
  usersList: 'Users.List',
  usersCreate: 'Users.Create',
  usersView: 'Users.View',
  usersEdit: 'Users.Edit',
  usersDelete: 'Users.Delete',
  ITadminEdit: 'ITadmin.Edit',
  schoolsItadminEdit: 'Schools.Itadmin.Edit',
  communitiesItadminEdit: 'Communities.Itadmin.Edit',
}

export const itAdminsLevels = {
  school: 'school',
  community: 'community',
  region: 'region',
}

export const defaultAlertTimeout = 3000

export const SESSION_ID_STORAGE_KEY = 'SESSION_ID_STORAGE_KEY'

type infoPageLinksType = {
  [key: string]: string
}

export const infoPageLinks: infoPageLinksType = {
  '30e7c950-66ab-4ef2-916f-781326cc8f71':
    'https://uanse.sharepoint.com/:f:/s/ITadmins_Console/EiErI70YaIFKndYmDWVArFcBllzCMCAFXviZJygQrXD6dA?e=LjqV9A',
  '20b3815d-3164-46f1-8dc7-4e329163e88a':
    'https://uapse.sharepoint.com/:f:/s/ITadmins_Console/EkuV9PfJV41Cv_2lvfZhB84BX-2QsTHFbhebrIhvgx077g?e=h2FZop',
  'feb13656-2e16-455f-9d06-09bc700983be':
    'https://uaese.sharepoint.com/:f:/s/ITadmins_Console/EkwCEJpOANVKpJ7R-JlXvXEBFBxzMUyGTxmWkVOOldW6LA?e=IOQUFQ',
  '33ee8cdc-9aab-4ff4-8e08-baad8892d714':
    'https://uawse.sharepoint.com/:f:/s/ITadmins_Console/EvXsGRt-cnFCh2q64RNxfHwBtHsUJ62BCRoM2clkNxLZYA?e=5DV8qC',
  'd917fb01-8183-40a9-a60b-cc34da161e8d':
    'https://uasse.sharepoint.com/:f:/s/ITadmins_Console/EoxaZWIJeM1CmZ4NWQhspZ4BkDS1RAXIZH5nlqTZ5KU5hg?e=InaWGB',
  'aa84fa03-0c1d-4779-b4f6-f2507a4414ac':
    'https://uacse.sharepoint.com/:f:/r/sites/ITadmins_Console/Shared%20Documents/General/MoE%20-%20O365%20IT-Admin%20-%20%D1%96%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%97?csf=1&web=1&e=mV3VEz',
  '91f67400-233f-4b21-994d-9228541cdbb6':
    'https://iea646.sharepoint.com/:f:/r/sites/ITadmins_Console/Shared%20Documents/General/MoE%20-%20O365%20IT-Admin%20-%20%D1%96%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D1%96%D1%97?csf=1&web=1&e=TqyoYC',
}
