import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@mui/material'
import PasswordIcon from '@mui/icons-material/Password'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination'
import {
  HeadItem,
  ExtendingTableTypes,
  ExtendingTableHeadTypes,
} from '../../types/table.types'
import { activeMFA, inactiveMFA, userRoles } from '../../constants/forms/users'
import { selectOptionTypes } from '../../types/formFields.types'
import { FilterFields } from '../../types/filter.types'
import { tablesConfig } from '../../constants/tables'
import { IpButton } from '../ui/IpButton'
import { IpIconButton } from '../ui/IpIconButton'
import { NotFundNotification } from '../NotFoundNotification'
import './index.scss'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

type OrderDirType = 'asc' | 'desc'
type OrderByType = string
interface EnhancedTableHeadProps<T> {
  orderDir: OrderDirType
  orderBy: OrderByType
  orderByFields: string[]
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: OrderByType
  ) => void
  onFilter?: (filter: FilterFields) => void
  rowCount: number
  headConfig: T
}

function EnhancedTableHead<T extends ExtendingTableHeadTypes>(
  props: EnhancedTableHeadProps<T>
) {
  const { orderDir, orderBy, orderByFields, onRequestSort, onFilter } = props

  const [selectedJobTitle, setSelectedJobTitle] = React.useState('')

  const onChangeRole = (event: SelectChangeEvent) => {
    const newRole = event.target.value
    setSelectedJobTitle(newRole)
    if (onFilter) {
      onFilter({
        filterBy: 'userRole',
        filterValue: newRole,
        orderDir,
        orderBy,
      } as FilterFields)
    }
  }

  const createSortHandler =
    (property: OrderByType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead className="t-head">
      <TableRow>
        {props.headConfig.map((item: HeadItem) => {
          return (
            <TableCell
              key={item.id}
              align={item.numeric ? 'right' : 'left'}
              sortDirection={orderBy === item.id ? orderDir : false}
              padding="normal"
            >
              {item.id === 'userRole' && (
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel id="demo-select-small">Роль</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selectedJobTitle}
                    label="Посада"
                    onChange={onChangeRole}
                  >
                    <MenuItem value="">
                      <em>Не вибрана</em>
                    </MenuItem>
                    {userRoles.map((role) => (
                      <MenuItem key={role.value} value={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {orderByFields.includes(item.id) ? (
                <TableSortLabel
                  active={orderBy === item.id}
                  direction={orderBy === item.id ? orderDir : 'asc'}
                  onClick={createSortHandler(item.id as OrderByType)}
                >
                  {item.label}
                </TableSortLabel>
              ) : (
                item.label
              )}
            </TableCell>
          )
        })}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

interface Props<T> {
  data: Array<T>
  type: string
  isPossibleSelectRow: boolean
  isPossibleSelectAllRows: boolean
  onRowClick?: (row: T) => void
  onNextPageClick?: () => void
  isNextBtnDisable?: boolean
  onSelectRow?: (newSelected: T[]) => void
  onSelectAll?: (selected: T[]) => void
  onPaginationClick?: () => void
  onDeleteIconClick?: (selected: T) => void
  onChangePassClick?: (selected: T) => void
  onSortAndFilter?: (filter: FilterFields) => void
  onRowsPerPageChanged?: (rowCount: string) => void
  defaultRowsPerPage: number
  orderByFields?: string[]
  className?: string
  defaultOrderDir: OrderDirType
  defaultOrderBy: string
  allowDelete: boolean
  allowView: boolean
  allowEdit: boolean
  allowChangePass?: boolean
  showFooter?: boolean
  notFundMessage?: string
}

export function CustomTable<T extends ExtendingTableTypes>({
  data,
  type,
  onRowClick,
  onNextPageClick,
  isNextBtnDisable,
  onDeleteIconClick,
  onChangePassClick,
  onSortAndFilter,
  onRowsPerPageChanged,
  defaultRowsPerPage,
  orderByFields,
  defaultOrderDir,
  defaultOrderBy,
  allowChangePass,
  showFooter = true,
  allowDelete,
  allowView,
  notFundMessage = 'Даних не знайдено',
}: Props<T>) {
  const [orderDir, setOrderDir] = React.useState<OrderDirType>(defaultOrderDir)
  const [orderBy, setOrderBy] = React.useState<OrderByType>(defaultOrderBy)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowCount = parseInt(event.target.value, 10)
    setRowsPerPage(rowCount)
    setPage(0)

    if (onRowsPerPageChanged) {
      onRowsPerPageChanged(rowCount.toString())
    }
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: OrderByType
  ) => {
    const isAsc = orderDir === 'asc'
    const newDirection = isAsc ? 'desc' : 'asc'
    setOrderDir(newDirection)
    setOrderBy(property)

    if (onSortAndFilter) {
      onSortAndFilter({
        orderDir: newDirection,
        orderBy: property,
        pageSize: (data.length > rowsPerPage
          ? data.length
          : rowsPerPage
        ).toString(),
      })
    }
  }

  const handleRowClick = (event: React.SyntheticEvent<EventTarget>, row: T) => {
    if (onRowClick) {
      onRowClick(row)
    }
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const renderCells = (row: T) => {
    return tablesConfig[type].map((cell: HeadItem, i: number) => {
      return (
        <TableCell key={`${cell.id}_${i}`} align="left">
          {(cell.id === 'userRole' &&
            userRoles.find(
              ({ value }: selectOptionTypes) => value === row.userRole
            )?.label) ||
            ''}
          {cell.id === 'mfaStatus' && row[cell.id] && activeMFA}
          {cell.id === 'mfaStatus' && !row[cell.id] && inactiveMFA}
          {/* add special cells here */}
          {cell.id !== 'mfaStatus' && cell.id !== 'userRole' && row[cell.id]}
        </TableCell>
      )
    })
  }

  const renderEmptyCells = () => {
    return tablesConfig[type].map((cell: HeadItem, i: number) => {
      return (
        <TableCell key={`${cell.id}_${i}`} align="left" padding="none">
          <span></span>
        </TableCell>
      )
    })
  }

  const PaginationRangeLabel = (paginationInfo: LabelDisplayedRowsArgs) => {
    return (
      <span>
        {paginationInfo.from} - {paginationInfo.to} із {paginationInfo.count}
      </span>
    )
  }

  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
      <TableContainer sx={{ maxHeight: 620 }}>
        <Table stickyHeader aria-labelledby="tableTitle">
          <EnhancedTableHead
            orderDir={orderDir}
            orderBy={orderBy}
            orderByFields={orderByFields || []}
            onRequestSort={handleRequestSort}
            onFilter={onSortAndFilter}
            rowCount={data.length}
            headConfig={tablesConfig[type]}
          />
          {data.length ? (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {renderCells(row)}
                      <TableCell>
                        <div className="table-btns">
                          {allowDelete && (
                            <IpIconButton
                              onClick={() => onDeleteIconClick?.(row)}
                            >
                              <DeleteOutlineIcon />
                            </IpIconButton>
                          )}
                          {allowView && (
                            <IpIconButton
                              onClick={(event) => handleRowClick(event, row)}
                            >
                              <VisibilityIcon />
                            </IpIconButton>
                          )}
                          {allowChangePass && (
                            <IpIconButton
                              onClick={() => onChangePassClick?.(row)}
                            >
                              <PasswordIcon />
                            </IpIconButton>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              {showFooter ? (
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      <IpButton
                        variant="contained"
                        classes="load-more-btn"
                        onClick={onNextPageClick}
                        disabled={isNextBtnDisable}
                      >
                        Підгрузити ще
                      </IpButton>
                    </TableCell>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      labelDisplayedRows={PaginationRangeLabel}
                      labelRowsPerPage={<span>Рядків на сторінці</span>}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              ) : null}
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={tablesConfig[type].length}>
                  <NotFundNotification message={notFundMessage} />
                </TableCell>
              </TableRow>
              <TableRow>{renderEmptyCells()}</TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  )
}
