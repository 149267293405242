export const validation = {
  maxCharsSchoolName: 225,
  maxLengthSchoolName: 'Має містити не більше ніж 225 символів',
  requiredSchoolName: "Обов'язкове поле 'Назва установи' не заповнене.",
  schoolNameRegexp: /^[^\\<>{}\][%$#|;&]*$/,
  notMatchSchoolName:
    "Значення поля 'Назва установи' містить недопустимі символи.",

  maxCharsSchoolId: 7,
  maxLengthSchoolId: 'Має містити не більше ніж 7 символів',
  requiredSchoolId: "Обов'язкове поле 'ID  установи' не заповнене.",
  notMatchSchoolId: "Значення поля 'ID  установи' містить недопустимі символи.",
  schoolIdRegexp: /^\d+$/,

  maxCommunityName: 225,
  maxLengthCommunityName: 'Має містити не більше ніж 225 символів',
  requiredCommunityName: "Обов'язкове поле 'Назва громади' не заповнене.",
  notMatchCommunityName:
    "Значення поля 'Назва громади' містить недопустимі символи.",
  communityNameRegexp: /^[^\\<>{}\][%"$@#|;&]*$/,

  maxUniqueCommunityId: 8,
  maxLengthUniqueCommunityId: 'Має містити не більше ніж 8 символів',
  requiredUniqueCommunityId: "Обов'язкове поле 'ID  установи' не заповнене.",
  alreadyExistsUniqueCommunityId:
    'Гормада з таким номером ID громади уже існує в системі.',
  notMatchUniqueCommunityId:
    "Значення поля 'ID громади' містить недопустимі символи.",
  uniqueCommunityIdRegexp: /^\d+$/,

  maxParentStructure: 50,
  maxLengthParentStructure: 'Має містити не більше ніж 225 символів',
  requiredParentStructure:
    "Обов'язкове поле  'Область, якій підпорядковується' не заповнене.",
  notMatchParentStructure:
    "Значення поля 'Область, якій підпорядковується' містить недопустимі символи.",
  parentStructureRegexp: /^\d+$/,

  invalidPhoneNumber: "Значення поля 'Телефон' містить помкові дані.",

  maxCharsLastName: 50,
  maxLengthLastName: 'Має містити не більше ніж 50 символів',
  requiredLastName: "Обов'язкове поле 'Прізвище' не заповнене.",
  invalidSymbolLastName:
    "Значення поля 'Прізвіще' містить недопустимі символи.",

  maxCharsFirstName: 50,
  maxLengthFirstName: 'Має містити не більше ніж 50 символів',
  requiredFirstName: "Обов'язкове поле 'Ім'я' не заповнене.",
  invalidSymbolFirstName: "Значення поля 'Ім'я' містить недопустимі символи.",

  maxCharsUserId: 50,
  maxLengthUserId: 'Має містити не більше ніж 50 символів',
  requiredUserId: "Обов'язкове поле 'Ідентифікуючий номер' не заповнене.",
  invalidSymbolUserId:
    "Значення поля 'Ідентифікуючий номер' містить недопустимі символи.",

  requiredUserRole: "Оберіть значення у полі 'Роль користувача",

  maxCharsJobTitle: 225,
  maxLengthJobTitle: 'Має містити не більше ніж 225 символів',
  notMatchJobTitle: "Значення поля 'Посада' містить недопустимі символи.",
  jobTitleRegexp: /^[^\\<>{}\][%"$@#|;&]*$/,

  nameValidation:
    /^[А-ЯІЄЇA-Za-zа-яієї]((-|'|’)?[А-ЯA-ZІЄЇа-яa-zієї]+){1,5}\s?[0-9]*$/,
  userIdValidation: /^[A-Za-z0-9-]+$/,

  maxSearch: 225,
  maxLengthSearch: 'Має містити не більше ніж 225 символів',
  notMatchSearch:
    "Значення поля 'Пошук по ключовим словам' містить недопустимі символи.",
  searchRegexp: /^[^\\<>{}\][%"$#|;&]*$/,

  notMatchCommunitySearch:
    "Значення поля 'Громада' містить недопустимі символи.",
  communitySearchRegexp: /^[^\\<>{}\][%$#|;&]*$/,
}

export const schoolRequiredFields: Array<string> = ['schoolName', 'schoolId']
export const userRequiredFields: Array<string> = [
  'lastName',
  'firstName',
  'userIdNumber',
  'userRole',
]
export const communityRequiredFields: Array<string> = [
  'communityName',
  'uniqueCommunityId',
]
