import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IpText } from '../ui/IpText'

import './index.scss'

type BackBtnProps = {
  path?: string
  text?: string
}

export const BackBtn: React.FC<BackBtnProps> = ({
  path,
  text = 'Повернутись',
}) => {
  const navigate = useNavigate()

  const goBack = () => {
    if (path) navigate(path)
    else navigate(-1)
  }

  return (
    <div className="btn-back" onClick={goBack}>
      <ArrowBackIcon className="btn-back__icon" />
      <IpText variant="body4">{text}</IpText>
    </div>
  )
}
