import React from 'react'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { CreateCommunityForm } from '../../components/CreateCommunityForm'

export const CreateCommunity: React.FC = () => {
  return (
    <>
      <BackBtn />
      <IpText variant="h4">Створити громаду</IpText>
      <CreateCommunityForm />
    </>
  )
}
