import React, { useEffect } from 'react'
import { trackEvents } from '../../constants/appInsights'
import { useTracking } from '../../hooks/tracking/useTracking'
import { useAppDispatch } from '../../redux/store'
import { getInfoPage } from '../../redux/thunk/infoPageThunk'
import { fetchRolesToCreateUser } from '../../redux/thunk/init'
import { InfoPageDataType } from '../../types/infoPage.types'
import { CatchErrorType } from '../../types/redux'

type PropsType = {
  children: React.ReactNode
}

const {
  successLoadOptions,
  errorLoadOptions,
  successInfoPageData,
  errorLoadInfoPageData,
} = trackEvents.init

export const Init: React.FC<PropsType> = (props: PropsType) => {
  const dispatch = useAppDispatch()
  const { trackEvent } = useTracking()

  useEffect(() => {
    getRolesToCreate()
    fetchInfoPageData()
  }, [])

  function getRolesToCreate() {
    const onSuccess = (options: Array<string>) => {
      trackEvent({ name: successLoadOptions, payload: options })
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorLoadOptions, payload: error })
    }
    dispatch(fetchRolesToCreateUser({ onError, onSuccess }))
  }

  function fetchInfoPageData() {
    const onSuccess = (options: InfoPageDataType) => {
      trackEvent({ name: successInfoPageData, payload: options })
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorLoadInfoPageData, payload: error })
    }
    dispatch(getInfoPage({ onError, onSuccess }))
  }

  return <>{props.children}</>
}
