import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsSchoolName,
  maxLengthSchoolName,
  requiredSchoolName,
  schoolNameRegexp,
  notMatchSchoolName,
} = validation

export const getSchoolNameSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsSchoolName, maxLengthSchoolName)
    .matches(schoolNameRegexp, notMatchSchoolName)

  schema = isRequired
    ? schema.required(requiredSchoolName)
    : schema.notRequired()

  return schema
}
