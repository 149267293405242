import React from 'react'
import { IpText } from '../ui/IpText'

export const MandatoryFieldNotification = () => {
  return (
    <IpText paragraph={true}>
      Поля відмічені зірочкою &quot;*&quot; є обов&apos;язковими для заповнення
    </IpText>
  )
}
