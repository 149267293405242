import React from 'react'
import { selectOptionTypes } from '../../../types/formFields.types'
import { IpCheckbox } from '../../ui/IpCheckbox'
import './index.scss'

type PropsType = {
  type: string
  name: string
  label: string
  variant?: 'outlined'
  required: boolean
  options?: Array<selectOptionTypes>
  placeholder?: string
  disabled?: boolean
  checked?: boolean
  value?: string
  touched?: boolean
  isLoading?: boolean
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export const CheckboxFormField: React.FC<PropsType> = ({
  handleBlur,
  handleChange,
  isLoading,
  value,
  ...fieldProps
}: PropsType) => {
  return (
    <div className="form-field checkbox-fiel">
      <IpCheckbox
        {...fieldProps}
        value={value}
        checked={value === 'on'}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={fieldProps.disabled || isLoading}
      />
    </div>
  )
}
