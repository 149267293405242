import { AlertColor } from '@mui/material'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CatchErrorType } from '../../types/redux'
import { apiGetRolesOptions } from '../../services/api'
import { getSSOToken } from '../selectors'
import { RootState } from '../store'

import { alertTypes } from '../../constants/common/index'
import { addAlert } from '../slices/alertsList'
import { setRolesToCreate } from '../slices/init'
import { prepareErrorText } from '../../utils'

type FetchRolesThunkPayloadType = {
  onSuccess: (options: Array<string>) => void
  onError: (error: CatchErrorType) => void
}

export const fetchRolesToCreateUser = createAsyncThunk<
  void,
  FetchRolesThunkPayloadType
>(
  'users/rolesOptions',
  // eslint-disable-next-line consistent-return
  async ({ onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetRolesOptions({ ssoToken })
      thunkAPI.dispatch(setRolesToCreate(data.options))
      onSuccess(data.options)
    } catch (e) {
      const error = e as CatchErrorType
      onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchUsersError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка!',
        })
      )
    }
  }
)
