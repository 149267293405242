import React, { ChangeEvent, Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'
import { SelectChangeEvent } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { IpButton } from '../ui/IpButton'
import { PhoneFormField } from '../formFields/PhoneFormField'
import { formFieldTypes, selectOptionTypes } from '../../types/formFields.types'
import { createUserFields } from '../../constants/forms/users'
import { userRequiredFields } from '../../constants/forms/validation'
import { useValidation } from '../../hooks/validation/useValidation'
import { useFormData } from '../../hooks/useFormData'

import { CatchErrorType } from '../../types/redux'
import { createUser } from '../../redux/thunk/usersThunk'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { TextFormField } from '../formFields/TextFormField'
import { SelectFormField } from '../formFields/SelectFormField'
import { CheckboxFormField } from '../formFields/CheckboxFormField'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { getRolesToCreate } from '../../redux/selectors'
import { routesConfiguration } from '../../routing'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { CreatedUserResponseType } from '../../types/user.types'
import './index.scss'

type formikValuesTypes = {
  lastName: string
  firstName: string
  userIdNumber: string
  userRole: string
  userJobTitle: string
  userPhoneNumber: string
  mfaStatus: string
  [key: string]: string
}

const { errorCreateUser, successCreateUser } = trackEvents.users

export const CreateUserForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { formSchema } = useValidation(userRequiredFields)
  const { isDataChanged, checkIsDataChanged, isFormValid, checkIsFormValid } =
    useFormData()

  const { trackEvent } = useTracking()

  const { isLoading } = useAppSelector((state) => state.users)
  const rolesToCreate = useAppSelector(getRolesToCreate)

  const createUserInitData = {
    lastName: '',
    firstName: '',
    userIdNumber: '',
    userRole: '',
    userJobTitle: '',
    userPhoneNumber: '',
    mfaStatus: 'off',
  }

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    touched,
    errors,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues: createUserInitData,

    validationSchema: formSchema,

    onSubmit: () => {
      const {
        lastName,
        firstName,
        userRole,
        userJobTitle,
        userIdNumber,
        userPhoneNumber,
        mfaStatus,
      } = values

      const dataToSubmit = {
        lastName,
        firstName,
        userRole,
        uniqueUserId: userIdNumber,
        jobTitle: userJobTitle || null,
        phone: userPhoneNumber?.replace(/ /g, '') || null,
        enableMfa: mfaStatus === 'on',
      }

      const payload = {
        body: dataToSubmit,
        onSuccess: (createdUser: CreatedUserResponseType) => {
          trackEvent({
            name: successCreateUser,
            payload: dataToSubmit,
          })
          navigate(routesConfiguration.successCreateUser.path, {
            state: createdUser,
          })
        },
        onError: (error: CatchErrorType) => {
          trackEvent({
            name: errorCreateUser,
            payload: error,
          })
        },
      }

      dispatch(createUser(payload))
    },
  })

  function setDefaultMfaStatus(name: string, value: string) {
    const defaultCheckedMfaRoles = [
      'Teacher',
      'ItAdmin',
      'OfficeStaff',
      'Principal',
    ]
    if (name === createUserFields.userRole.name) {
      if (
        defaultCheckedMfaRoles.includes(value) &&
        !touched[createUserFields.mfaStatus.name]
      ) {
        setFieldValue(createUserFields.mfaStatus.name, 'on')
      } else if (
        !defaultCheckedMfaRoles.includes(value) &&
        !touched[createUserFields.mfaStatus.name]
      ) {
        setFieldValue(createUserFields.mfaStatus.name, 'off')
      }
    }
  }

  function changeSelectHandler(event: SelectChangeEvent<string>) {
    const { value, name } = event.target
    setFieldValue(name, value)
    setDefaultMfaStatus(name, value)
  }

  function changeCheckboxHandler(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target
    setFieldTouched(name, true)
    setFieldValue(name, value === 'on' ? 'off' : 'on')
  }

  function onChangePhone(phoneValues: {
    floatValue: number | undefined
    formattedValue: string
    value: string
  }) {
    const value = phoneValues.floatValue ? phoneValues.formattedValue : ''
    setFieldValue(createUserFields.userPhoneNumber.name, value)
    if (!value && touched[createUserFields.userPhoneNumber.name]) {
      setFieldTouched(createUserFields.userPhoneNumber.name, false)
    }
  }

  function filterRoleOptions(options: Array<selectOptionTypes>) {
    return options.filter(({ value }) => rolesToCreate.includes(value))
  }

  useEffect(() => {
    checkIsDataChanged(values, createUserInitData)
  }, [values, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(createUserFields).map(
        (fieldProps: formFieldTypes, index) => {
          const isPhoneField =
            fieldProps.name === createUserFields.userPhoneNumber.name
          return (
            <Fragment key={index}>
              {fieldProps.type === 'text' && !isPhoneField && (
                <TextFormField
                  {...fieldProps}
                  isLoading={isLoading}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors[fieldProps.name]}
                />
              )}
              {fieldProps.type === 'select' && (
                <SelectFormField
                  {...fieldProps}
                  isLoading={isLoading}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={changeSelectHandler}
                  error={errors[fieldProps.name]}
                  value={values[fieldProps.name]}
                  options={
                    fieldProps.options && filterRoleOptions(fieldProps.options)
                  }
                />
              )}
              {fieldProps.type === 'checkbox' && (
                <CheckboxFormField
                  {...fieldProps}
                  value={values[fieldProps.name]}
                  checked={values[fieldProps.name] === 'on'}
                  handleChange={changeCheckboxHandler}
                  handleBlur={handleBlur}
                  disabled={fieldProps.disabled || isLoading}
                />
              )}
              {fieldProps.type === 'text' && isPhoneField && (
                <PhoneFormField
                  {...fieldProps}
                  value={values[fieldProps.name]}
                  onChangePhone={onChangePhone}
                  error={errors[fieldProps.name]}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                />
              )}
            </Fragment>
          )
        }
      )}

      <MandatoryFieldNotification />

      <IpButton
        variant="contained"
        size="large"
        type="submit"
        disabled={!isFormValid || !isDataChanged || isLoading}
      >
        {isLoading ? 'Збереження...' : 'Зберегти'}
      </IpButton>
    </form>
  )
}
