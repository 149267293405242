import * as microsoftTeams from '@microsoft/teams-js'

export const getAuthSSOToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    microsoftTeams.initialize()
    const successCallback = (token: string) => resolve(token)
    const failureCallback = (error: string) => reject(error)
    const authTokenRequest = { successCallback, failureCallback }
    microsoftTeams.authentication.getAuthToken(authTokenRequest)
  })
}
