import { ErrorType } from '../types/redux'

export type ConcatQueryParamsType = {
  [key: string]: string | Array<string>
}

export function concatQueryParams(params: ConcatQueryParamsType) {
  const query = `?${Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key]) && params[key].length > 1) {
        return Array.from(params[key])
          .map((item: string) => `${key}=${item}`)
          .join('&')
      }
      return params[key] ? `${key}=${params[key]}` : ''
    })
    .filter(Boolean)
    .join('&')}`
  return params ? query : ''
}

export function prepareErrorText(errors: ErrorType[]) {
  return errors.map((error) => error.errorMessage).join(', ')
}

export const concatCommunityNameAndId = (name: string, id: string) => {
  return `(${id}) ${name}`
}

export const normalizePhone = (phone: string) => phone.replace(/ /g, '')
