import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CurrentCommunityType,
  CreatedCommunityResponseType,
  CommunitiesSliceType,
  CommunitiesTransformResponseType,
} from '../../types/communities.types'
import {
  fetchCommunities,
  createCommunity,
  fetchCommunityById,
  editCommunity,
} from '../thunk/communitiesThunk'

import { FilterFields } from '../../types/filter.types'
import { defaultPageSize } from '../../constants/forms/users'

const initialState: CommunitiesSliceType = {
  data: { currentPage: [], skipToken: '', pageSize: 5 },
  currentCommunity: {
    id: '',
    itAdmins: [],
    name: '',
    regionId: '',
    regionName: '',
    uniqueCommunityId: '',
    sharedMailbox: '',
  },
  filter: {
    search: '',
    filterBy: '',
    filterValue: '',
    orderBy: 'uniqueCommunityId',
    orderDir: 'asc',
    pageSize: defaultPageSize,
  },
  searchCommunitiesResult: {
    communities: [],
    storedSearchValue: '',
    prevStoredSearchValue: '',
  },
  isLoading: false,
  error: '',
}

export const communitiesSlice = createSlice({
  name: 'communities',
  initialState,
  reducers: {
    loadMoreCommunities: (
      state,
      { payload }: PayloadAction<CommunitiesTransformResponseType>
    ) => {
      state.data.currentPage = [
        ...state.data.currentPage,
        ...payload.currentPage,
      ]
      state.data.skipToken = payload.skipToken
      state.isLoading = false
    },
    firstCommunitiesLoad: (
      state,
      { payload }: PayloadAction<CommunitiesTransformResponseType>
    ) => {
      state.data = payload
      state.isLoading = false
    },
    updateCommunitiesFilter: (
      state,
      { payload }: PayloadAction<FilterFields>
    ) => {
      state.filter = payload
    },
    fetchCommunity: (
      state,
      { payload }: PayloadAction<CurrentCommunityType>
    ) => {
      state.currentCommunity = payload
    },
    setSearchCommunities: (
      state,
      { payload }: PayloadAction<CreatedCommunityResponseType[]>
    ) => {
      state.searchCommunitiesResult.communities = payload
    },
    saveSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchCommunitiesResult.storedSearchValue = payload
    },
    updateCommunityName: (state, { payload }: PayloadAction<string>) => {
      state.currentCommunity.name = payload
    },
    savePrevStoredSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.searchCommunitiesResult.prevStoredSearchValue = payload
    },
  },
  extraReducers: {
    [fetchCommunityById.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchCommunityById.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [fetchCommunityById.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchCommunities.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchCommunities.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [fetchCommunities.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createCommunity.pending.type]: (state) => {
      state.isLoading = true
    },
    [createCommunity.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [createCommunity.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [editCommunity.pending.type]: (state) => {
      state.isLoading = true
    },
    [editCommunity.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [editCommunity.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  loadMoreCommunities,
  firstCommunitiesLoad,
  updateCommunitiesFilter,
  fetchCommunity,
  setSearchCommunities,
  saveSearchValue,
  updateCommunityName,
  savePrevStoredSearchValue,
} = communitiesSlice.actions

export default communitiesSlice.reducer
