import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxParentStructure,
  maxLengthParentStructure,
  requiredParentStructure,
  notMatchParentStructure,
  parentStructureRegexp,
} = validation

export const getParentStructureSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxParentStructure, maxLengthParentStructure)
    .matches(parentStructureRegexp, notMatchParentStructure)

  schema = isRequired
    ? schema.required(requiredParentStructure)
    : schema.notRequired()

  return schema
}
