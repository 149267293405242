import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { init } from './slices/init'
import { alertsList } from './slices/alertsList'
import { loader } from './slices/loader'
import { permissions } from './slices/permissions'
import communities from './slices/communitySlice'
import users from './slices/usersSlice'
import schools from './slices/schoolsSlice'
import auth from './slices/auth'
import infopage from './slices/infoPageSlice'

export const store = configureStore({
  reducer: {
    alertsList,
    loader,
    users,
    communities,
    schools,
    auth,
    permissions,
    init,
    infopage,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type RootState = ReturnType<typeof store.getState>

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
