import colors from '../colors'

const colorNames = ['primary', 'secondary', 'error', 'success', 'warning']

const borderNames = ['solid', 'dotted', 'dashed']

const colorStyles = colorNames.reduce((acc, color) => {
  return {
    ...acc,
    [`&.IpDivider-color-${color}`]: {
      'div&': {
        '&:before, &:after': {
          // @ts-ignore
          borderColor: colors[color].main,
        },
      },
      'hr&': {
        // @ts-ignore
        borderColor: colors[color].main,
      },
    },
  }
}, {})

const borderStyles = borderNames.reduce((acc, border) => {
  return {
    ...acc,
    [`&.IpDivider-${border}`]: {
      'div&': {
        '&:before, &:after': {
          borderTopStyle: border,
        },
      },
      'hr&': {
        borderTopStyle: border,
      },
    },
  }
}, {})

export default {
  MuiDivider: {
    styleOverrides: {
      root: {
        borderBottom: 0,
        borderLeft: 0,
        borderRight: 0,

        ...colorStyles,
        ...borderStyles,

        '&.IpDivider-color-info': {
          'div&': {
            '&:before, &:after': {
              borderColor: colors.grey['600'],
            },
          },
          'hr&': {
            borderColor: colors.grey['600'],
          },
        },

        '&.IpDivider-thin': {
          borderTopWidth: '1px',
          '&:before, &:after': {
            borderTopWidth: '1px',
          },
        },

        '&.IpDivider-medium': {
          borderTopWidth: '2px',
          '&:before, &:after': {
            borderTopWidth: '2px',
          },
        },

        '&.IpDivider-bold': {
          borderTopWidth: '3px',
          '&:before, &:after': {
            borderTopWidth: '3px',
          },
        },
      },
    },
  },
}
