import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, DialogActions, AlertColor } from '@mui/material'
import { IpText } from '../../components/ui/IpText'
import { EditSchoolForm } from '../../components/EditSchoolForm'
import { BackBtn } from '../../components/BackBtn'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { getCurrentSchool } from '../../redux/selectors'
import { routesConfiguration } from '../../routing'
import { CustomTable } from '../../components/CustomTable'
import { usePermissions } from '../../hooks/usePermissions'
import { alertTypes, permissionResourcesKeys } from '../../constants/common'
import { SchoolItAdminType } from '../../types/schools.types'
import { fetchUserById, resetPassword } from '../../redux/thunk/usersThunk'
import { LoaderPortal } from '../../components/Loader/LoaderPortal'
import { CustomModal } from '../../components/CustomModal'
import { IpButton } from '../../components/ui/IpButton'
import { AdminUserRowType } from '../../types/user.types'
import { useTracking } from '../../hooks/tracking/useTracking'
import { addAlert } from '../../redux/slices/alertsList'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'

const { schoolsItadminEdit } = permissionResourcesKeys

const { errorReseteUserPassword, successReseteUserPassword } = trackEvents.users

export const SchoolProfile: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentSchool = useAppSelector(getCurrentSchool)
  const { getIsOperationAllowed } = usePermissions()
  const { isLoading } = useAppSelector((state) => state.users)
  const [isResetPassPopupOpen, setResetPassPopupOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState({} as AdminUserRowType)
  const { trackEvent } = useTracking()

  const fetchItAdminData = ({ id }: SchoolItAdminType) => {
    const onSuccess = () => {
      navigate(routesConfiguration.userProfile.path)
    }
    dispatch(fetchUserById({ id, onSuccess }))
  }

  const onChangePassClick = (user: AdminUserRowType) => {
    setCurrentUser(user)
    setResetPassPopupOpen(true)
  }

  const onResetPassword = () => {
    const onSuccess = () => {
      trackEvent({ name: successReseteUserPassword, payload: currentUser })
      dispatch(
        addAlert({
          id: 'passwordReset',
          type: alertTypes.success as AlertColor,
          text: 'Вітаємо! Пароль успішно скинуто',
        })
      )
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorReseteUserPassword, payload: error })
    }
    dispatch(resetPassword({ id: currentUser.id, onError, onSuccess }))
    setResetPassPopupOpen(false)
  }

  return (
    <>
      <BackBtn />
      <LoaderPortal show={isLoading} />
      <IpText variant="h4">Профіль навчального закладу</IpText>
      <EditSchoolForm initialSchoolData={currentSchool} />
      {currentSchool.itAdmins.length ? (
        <Box sx={{ mt: 5 }}>
          <IpText variant="h5">ІТ адміністратори навчального закладу:</IpText>
          <CustomTable
            data={currentSchool.itAdmins}
            type="itAdminsList"
            isPossibleSelectRow={true}
            isPossibleSelectAllRows={true}
            onRowClick={fetchItAdminData}
            defaultOrderBy="displayName"
            defaultOrderDir="asc"
            allowDelete={getIsOperationAllowed([])}
            allowView={getIsOperationAllowed([schoolsItadminEdit])}
            allowEdit={getIsOperationAllowed([schoolsItadminEdit])}
            allowChangePass={getIsOperationAllowed([schoolsItadminEdit])}
            onChangePassClick={onChangePassClick}
            showFooter={false}
            defaultRowsPerPage={5}
          />
        </Box>
      ) : null}
      <CustomModal
        headerText={`Ви дійсно хочете скинути пароль ІТ Адміністратору ${currentUser.displayName}?`}
        isOpen={isResetPassPopupOpen}
      >
        <DialogActions>
          <IpButton
            onClick={() => setResetPassPopupOpen(false)}
            variant="outlined"
            size="small"
          >
            Ні
          </IpButton>
          <IpButton onClick={onResetPassword} variant="contained" size="small">
            Так
          </IpButton>
        </DialogActions>
      </CustomModal>
    </>
  )
}
