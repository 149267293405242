import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IpText } from '../../components/ui/IpText'
import { IpButton } from '../../components/ui/IpButton'

import { useAppDispatch, useAppSelector } from '../../redux/store'
import { usePermissions } from '../../hooks/usePermissions'
import { routesConfiguration } from '../../routing'

import { CustomTable } from '../../components/CustomTable'
import { LoaderPortal } from '../../components/Loader/LoaderPortal'
import { FilterForm } from '../../components/FilterForm'

import { SchoolsListTableData } from '../../types/table.types'
import { FilterFields } from '../../types/filter.types'
import { itAdminsLevels, permissionResourcesKeys } from '../../constants/common'
import { defaultPageSize } from '../../constants/forms/users'
import { filterSchoolsList } from '../../constants/forms/schools'

import { fetchSchoolById, fetchSchools } from '../../redux/thunk/schoolsThunk'
import { updateSchoolsFilter } from '../../redux/slices/schoolsSlice'
import {
  getCommunities,
  getSchoolFilter,
  infoPageSelector,
} from '../../redux/selectors'
import { searchCommunities } from '../../redux/thunk/communitiesThunk'
import { useTracking } from '../../hooks/tracking/useTracking'
import { CreatedSchoolResponseType } from '../../types/schools.types'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'
import { savePrevStoredSearchValue } from '../../redux/slices/communitySlice'
import { concatCommunityNameAndId } from '../../utils'

const { schoolsCreate, schoolsView, schoolsEdit } = permissionResourcesKeys

const {
  errorLoadSchoolProfile,
  successLoadSchoolProfile,
  errorLoadschoolsList,
  successLoadSchoolsList,
} = trackEvents.schools

export const SchoolsList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getIsOperationAllowed } = usePermissions()
  const {
    searchCommunitiesResult: { communities },
  } = useAppSelector(getCommunities)

  const { pageSize } = useAppSelector(getSchoolFilter)

  const {
    data: { level: adminLevel },
  } = useAppSelector(infoPageSelector)
  const isRegionAdmin = itAdminsLevels.region === adminLevel.toLowerCase()
  const [isNextBtnDisable, setNextBtnDisable] = useState(false)
  const { trackEvent } = useTracking()

  const orderByFields = ['uniqueSchoolId']

  const {
    data: { currentPage, skipToken },
    filter,
    isLoading,
  } = useAppSelector((state) => state.schools)

  const transformedCommunities = useMemo(
    () =>
      currentPage.map((item) => ({
        id: item.id,
        name: item.name,
        uniqueSchoolId: item.uniqueSchoolId,
        hierarchicalLevel: item.communityId,
        parentStructure: item.regionId,
      })),
    [currentPage]
  )

  useEffect(() => {
    if (skipToken?.length) {
      setNextBtnDisable(false)
    } else {
      setNextBtnDisable(true)
    }
  }, [skipToken])

  const openSchoolProfile = (row: SchoolsListTableData) => {
    const onSuccess = (user: CreatedSchoolResponseType) => {
      trackEvent({ name: successLoadSchoolProfile, payload: user })
      navigate(routesConfiguration.schoolProfile.path)
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorLoadSchoolProfile, payload: error })
      navigate(routesConfiguration.schoolProfile.path)
    }
    const payload = {
      id: row.id,
      onSuccess,
      onError,
    }
    dispatch(fetchSchoolById(payload))
  }

  useEffect(() => {
    if (filter.communityId) {
      dispatch(searchCommunities({ search: filter.communityId }))
    }
  }, [filter])

  const onNextPageClick = () => {
    const payload = {
      params: {
        ...filter,
        skipToken: skipToken || '',
      },
      onSuccess: onSuccessFetchSchools,
      onError: (error: CatchErrorType) => onErrorFetchSchools(error),
    }
    dispatch(fetchSchools(payload))
  }

  const getFilterFieldsToSubmit = ({
    communityId,
    orderBy,
    orderDir,
    search,
  }: FilterFields) => {
    return {
      communityId,
      orderBy,
      orderDir,
      search,
    }
  }

  const submitHandler = (newFilters: FilterFields) => {
    const newFiltersToSubmit = getFilterFieldsToSubmit(newFilters)
    dispatch(updateSchoolsFilter({ ...filter, ...newFiltersToSubmit }))
  }

  const onSortAndFilterHandle = (newFilters: FilterFields) => {
    const newFiltersToSubmit = getFilterFieldsToSubmit(newFilters)
    dispatch(updateSchoolsFilter({ ...filter, ...newFiltersToSubmit }))
  }

  const onRowsPerPageChanged = (rowsCount: string) => {
    dispatch(updateSchoolsFilter({ ...filter, pageSize: rowsCount }))
  }

  const saveCommunity = (communityId: string) => {
    const community = communities.find(
      (item) => communityId === item.uniqueCommunityId
    )
    if (community) {
      dispatch(
        savePrevStoredSearchValue(
          concatCommunityNameAndId(community.name, community.uniqueCommunityId)
        )
      )
    }
  }

  const resetSavedCommunity = () => {
    dispatch(savePrevStoredSearchValue(''))
  }

  useEffect(() => {
    const payload = {
      params: { pageSize: defaultPageSize, ...filter },
      onSuccess: onSuccessFetchSchools,
      onError: (error: CatchErrorType) => onErrorFetchSchools(error),
    }
    dispatch(fetchSchools(payload))
    if (filter.communityId) {
      saveCommunity(filter.communityId)
    } else {
      resetSavedCommunity()
    }
  }, [filter])

  function onSuccessFetchSchools() {
    trackEvent({
      name: successLoadSchoolsList,
      payload: { pageSize: defaultPageSize, ...filter },
    })
  }

  function onErrorFetchSchools(error: CatchErrorType) {
    trackEvent({ name: errorLoadschoolsList, payload: error })
  }

  return (
    <>
      <LoaderPortal show={isLoading} />
      <IpText variant="h4">Навчальні заклади</IpText>
      <FilterForm
        submitHandler={submitHandler}
        list={filterSchoolsList}
        defaultOrderBy="uniqueSchoolId"
        isWithCommunity={isRegionAdmin}
        orderDir={filter.orderDir || 'asc'}
      />

      <CustomTable
        className="edit-list-form"
        data={transformedCommunities}
        type="schoolsList"
        isPossibleSelectRow={true}
        isPossibleSelectAllRows={true}
        onNextPageClick={onNextPageClick}
        isNextBtnDisable={isNextBtnDisable}
        onSortAndFilter={onSortAndFilterHandle}
        orderByFields={orderByFields}
        onRowClick={openSchoolProfile}
        onRowsPerPageChanged={onRowsPerPageChanged}
        defaultRowsPerPage={pageSize ? +pageSize : 5}
        defaultOrderBy="uniqueSchoolId"
        defaultOrderDir="asc"
        allowDelete={getIsOperationAllowed([])}
        allowView={getIsOperationAllowed([schoolsView])}
        allowEdit={getIsOperationAllowed([schoolsEdit])}
        notFundMessage="Відсутні дані, що відповідають заданим критеріям пошуку"
      />

      {getIsOperationAllowed([schoolsCreate]) && (
        <IpButton
          classes="create-school-btn"
          variant="contained"
          size="large"
          onClick={() => navigate(routesConfiguration.createSchool.path)}
        >
          Створити навчальний заклад
        </IpButton>
      )}
    </>
  )
}
