import React from 'react'
import { BackBtn } from '../../components/BackBtn'
import { LoaderPortal } from '../../components/Loader/LoaderPortal'
import { IpText } from '../../components/ui/IpText'
import { infoPageLinks, itAdminsLevels } from '../../constants/common'
import { getTenantId, infoPageSelector } from '../../redux/selectors'
import { useAppSelector } from '../../redux/store'

import './index.scss'

export const InfoPage: React.FC = () => {
  const { data, isLoading } = useAppSelector(infoPageSelector)
  const tenantId = useAppSelector(getTenantId)

  const link = infoPageLinks[tenantId] || '#'

  return (
    <>
      <LoaderPortal show={isLoading} />
      <BackBtn />
      <IpText variant="h4">Інформаційна сторінка</IpText>

      <IpText variant="subtitle2">Шановний користувач,</IpText>
      <IpText variant="subtitle2">
        Ваш обліковий запис відносить до установи:
        <strong> {data.adminUnitDescription}</strong>
      </IpText>
      {data.parentAdminUnitDescription && (
        <IpText variant="subtitle2">
          Керуюча установа: <strong> {data.parentAdminUnitDescription}</strong>
        </IpText>
      )}
      <IpText variant="subtitle2" classes="paragraph-block">
        Цей застосунок призначений для реалізації ієрархічної моделі керування
        обліковими записами користувачів освітніх закладів України в рамках
        використання функціональності Microsoft Office 365.
      </IpText>
      <IpText variant="subtitle2" classes="paragraph-block">
        Опис функціональності застосунку та інструкції по користуванню
        знаходяться за<span> </span>
        <a
          href={`${link}`}
          target="_blank"
          rel="noreferrer"
          className="info-page-link"
        >
          посиланням
        </a>
        .
      </IpText>

      {data.level.toLowerCase() === itAdminsLevels.school && (
        <IpText variant="subtitle2" classes="paragraph-block">
          У разі виникнення технічних питань, звертайтесь до вашої керуючої
          установи за електронною адресою:{' '}
          <a href={`mailto:${data.parentAdminUnitSharedMailbox}`}>
            {data.parentAdminUnitSharedMailbox}
          </a>
          . У випадку відсутності зворотного зв’язку більше 5 робочих днів або у
          екстрених випадках, направляйте звернення за електронною адресою
          обласного управління:{' '}
          {
            <a href={`mailto:${data.regionSharedMailbox}`}>
              {data.regionSharedMailbox}
            </a>
          }
          .
        </IpText>
      )}

      {data.level.toLowerCase() === itAdminsLevels.community && (
        <IpText variant="subtitle2" classes="paragraph-block">
          У разі виникнення технічних питань, звертайтесь до вашої керуючої
          установи за електронною адресою:{' '}
          {
            <a href={`mailto:${data.regionSharedMailbox}`}>
              {data.regionSharedMailbox}
            </a>
          }
        </IpText>
      )}

      {data.level.toLowerCase() === itAdminsLevels.region && (
        <IpText variant="subtitle2" classes="paragraph-block">
          У разі виникнення технічних питань, звертайтесь до адміністраторів
          центрального рівня за електронною адресою:{' '}
          {<a href={'mailto:admin@iea.gov.ua'}>admin@iea.gov.ua</a>}
        </IpText>
      )}
    </>
  )
}
