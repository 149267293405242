import React from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { AutocompleteOptionType } from '../../../types/filter.types'

type PropsType = {
  type: string
  name: string
  label: string
  variant?: 'outlined'
  required: boolean
  options: Array<AutocompleteOptionType>
  placeholder?: string
  disabled?: boolean
  value?: string
  touched?: boolean
  isLoading?: boolean
  error?: string
  noOptionsText: string
  freeSolo: boolean
  loadingText?: string
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlurSearch: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | AutocompleteOptionType | null
  ) => void
}

export const SearchAndSelectFormField: React.FC<PropsType> = (
  props: PropsType
) => {
  const {
    name,
    value,
    label,
    required,
    options,
    touched,
    error,
    onSearch,
    onBlurSearch,
    isLoading,
    noOptionsText,
    freeSolo,
    onChange,
    loadingText,
    disabled,
  } = props
  const validationError = touched && error
  return (
    <div className="form-field">
      <FormControl fullWidth>
        <Autocomplete
          disabled={disabled}
          freeSolo={freeSolo}
          fullWidth={true}
          options={options}
          loading={isLoading}
          noOptionsText={noOptionsText}
          onChange={onChange}
          onBlur={onBlurSearch}
          clearIcon={<span></span>}
          loadingText={loadingText}
          value={value}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={`${label}${required ? '*' : ''}`}
                onChange={onSearch}
                onBlur={onBlurSearch}
                value={value}
                name={name}
                error={!!validationError}
                helperText={validationError}
              />
            )
          }}
        />
      </FormControl>
    </div>
  )
}
