import { AlertColor } from '@mui/material'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { InfoPageDataType } from '../../types/infoPage.types'
import { CatchErrorType } from '../../types/redux'
import { apiGetInfoPageData } from '../../services/api'
import { getSSOToken } from '../selectors'
import { RootState } from '../store'

import { alertTypes } from '../../constants/common/index'

import { addAlert } from '../slices/alertsList'
import { setInformation } from '../slices/infoPageSlice'

type GetInfoPageThunkPayloadType = {
  onSuccess: (options: InfoPageDataType) => void
  onError: (error: CatchErrorType) => void
}

export const getInfoPage = createAsyncThunk<void, GetInfoPageThunkPayloadType>(
  'infopage/fetch',
  // eslint-disable-next-line consistent-return
  async ({ onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetInfoPageData({ ssoToken })
      thunkAPI.dispatch(setInformation(data))
      onSuccess(data)
    } catch (e) {
      const error = e as CatchErrorType
      onError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'infopageError',
          type: alertTypes.error as AlertColor,
          text: 'Не вдалося завантажити інформацію',
        })
      )
      return thunkAPI.rejectWithValue('Не вдалося завантажити інформацію')
    }
  }
)
