import React, { FocusEvent } from 'react'
import { FormControl, InputLabel, TextField } from '@mui/material'
import NumberFormat from 'react-number-format'
import './index.scss'

type PropsTypes = {
  type: string
  name: string
  label: string
  variant?: 'outlined'
  required: boolean
  value?: string
  error?: string
  touched?: boolean
  disabled?: boolean
  handleBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChangePhone?: (values: {
    floatValue: number | undefined
    formattedValue: string
    value: string
  }) => void
}

export const PhoneFormField: React.FC<PropsTypes> = (props: PropsTypes) => {
  return (
    <div className="phone-form-field">
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-helper-label"
          shrink={true}
          focused={true}
          classes={{
            focused: 'phone-field-focused',
          }}
        >
          {props.label}
          {props.required ? '*' : ''}
        </InputLabel>
        <NumberFormat
          customInput={TextField}
          value={props.value}
          format="+380 #########"
          placeholder="+380"
          allowEmptyFormatting={true}
          onValueChange={props.onChangePhone}
          helperText={props.touched && props.error}
          error={!!props.error && props.touched}
          onBlur={props.handleBlur}
          disabled={props.disabled}
          name="userPhoneNumber"
        />
      </FormControl>
    </div>
  )
}
