import React, { ReactNode, MouseEvent } from 'react'
import { Button } from '@mui/material'
import { IpButtonPropTypes } from '../../../types/ui/IpButton.types'

type IpButtonPropsTypesReact = IpButtonPropTypes & {
  onClick?: (event: MouseEvent<HTMLElement>) => void
  children: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  type?: string
}

export function IpButton(props: IpButtonPropsTypesReact) {
  const {
    variant,
    size,
    color,
    disabled,
    fullWidth,
    classes,
    href,
    target,
    onClick,
    children,
    startIcon,
    endIcon,
  } = props

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      href={href}
      target={target}
      classes={{ root: classes }}
      component={href ? 'a' : 'button'}
      type={props.type}
    >
      <span className="button-content">{children}</span>
    </Button>
  )
}
