import { AlertColor } from '@mui/material'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  FetchCommunitiesThunkPayloadType,
  CreateCommunityThunkPayloadType,
  EditCommunityThunkPayloadType,
} from '../../types/communities.types'
import { CatchErrorType } from '../../types/redux'

import {
  apiEditCommunity,
  apiCreteCommunity,
  apiGetCommunities,
  apiGetCommunityById,
} from '../../services/api'

import { RootState } from '../store'
import { getSSOToken } from '../selectors'

import { alertTypes } from '../../constants/common/index'

import { addAlert } from '../slices/alertsList'

import {
  fetchCommunity,
  firstCommunitiesLoad,
  loadMoreCommunities,
  setSearchCommunities,
  updateCommunityName,
} from '../slices/communitySlice'
import { FilterFields } from '../../types/filter.types'
import { prepareErrorText } from '../../utils'

export const fetchCommunities = createAsyncThunk<
  void,
  FetchCommunitiesThunkPayloadType
>(
  'community/fetch',
  // eslint-disable-next-line consistent-return
  async ({ params, onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetCommunities({ params, ssoToken })

      if (params.skipToken) {
        thunkAPI.dispatch(loadMoreCommunities(data))
      } else {
        thunkAPI.dispatch(firstCommunitiesLoad(data))
      }
      onSuccess()
      thunkAPI.dispatch(
        addAlert({
          id: 'communitiesFetchSuccess',
          type: alertTypes.success as AlertColor,
          text: 'Дані громад успішно оновилися',
        })
      )
    } catch (e) {
      const error = e as CatchErrorType
      onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchCommunitiesError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Не вдалося завантажити громади',
        })
      )

      return thunkAPI.rejectWithValue('Не вдалося завантажити громади')
    }
  }
)

export const searchCommunities = createAsyncThunk<void, FilterFields>(
  'community/fetch',
  // eslint-disable-next-line consistent-return
  async (params, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetCommunities({ params, ssoToken })
      thunkAPI.dispatch(setSearchCommunities(data.currentPage))
    } catch (e) {
      return thunkAPI.rejectWithValue('Не вдалося завантажити громади')
    }
  }
)

type FetchCommunityByIdPayloadType = {
  id: string
  onSuccess?: () => void
  onError?: (error: CatchErrorType) => void
}

export const fetchCommunityById = createAsyncThunk<
  void,
  FetchCommunityByIdPayloadType
>(
  'community/fetchCommunity',
  // eslint-disable-next-line consistent-return
  async (payload, thunkAPI) => {
    const { id, onSuccess, onError } = payload
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetCommunityById({ id, ssoToken })
      thunkAPI.dispatch(fetchCommunity(data))
      if (onSuccess) {
        onSuccess()
      }
    } catch (e) {
      const error = e as CatchErrorType
      if (onError) onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchCommunityError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході відкриття профіля громади',
        })
      )

      return thunkAPI.rejectWithValue('Не вдалося загрузити профіль громади')
    }
  }
)

export const createCommunity = createAsyncThunk<
  void,
  CreateCommunityThunkPayloadType
>(
  'communities/create',
  // eslint-disable-next-line consistent-return
  async ({ body, onSuccess, onError }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiCreteCommunity({ body, ssoToken })
      onSuccess()
      thunkAPI.dispatch(
        addAlert({
          id: 'communityCreated',
          type: alertTypes.success as AlertColor,
          text: 'Вітаємо! Нову громаду створено!',
        })
      )
    } catch (e: unknown) {
      const error = e as CatchErrorType
      onError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'createSchoolError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході створення нової громади',
        })
      )
      return thunkAPI.rejectWithValue('Не вдалося створити громаду')
    }
  }
)

export const editCommunity = createAsyncThunk<
  void,
  EditCommunityThunkPayloadType
>(
  'communities/edit',
  // eslint-disable-next-line consistent-return
  async (payload, thunkAPI) => {
    const { body, onSuccess, onError } = payload
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiEditCommunity({ body, ssoToken })
      if (onSuccess) onSuccess()
      thunkAPI.dispatch(updateCommunityName(body.name))
    } catch (e) {
      const error = e as CatchErrorType
      if (onError) onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'editCommunityError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході оновлення даних громади',
        })
      )

      return thunkAPI.rejectWithValue('Не вдалося оновити даны про громаду')
    }
  }
)
