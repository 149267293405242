import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsSchoolId,
  maxLengthSchoolId,
  requiredSchoolId,
  schoolIdRegexp,
  notMatchSchoolId,
} = validation

export const getSchoolIdSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsSchoolId, maxLengthSchoolId)
    .matches(schoolIdRegexp, notMatchSchoolId)

  schema = isRequired ? schema.required(requiredSchoolId) : schema.notRequired()

  return schema
}
