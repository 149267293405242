import React, { useEffect } from 'react'
import { decodeJwt, JWTPayload } from 'jose'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { authThunk } from '../../redux/thunk/auth'
import { getAuthState } from '../../redux/selectors'
import { setUserPermissionList } from '../../redux/slices/permissions'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { setTenenatId } from '../../redux/slices/auth'

type PropsType = {
  children: React.ReactNode
}
const preventSSOExpiration = 6000 * 10 // 10 minutes
const checkTokenExpirationInterval = 6000 * 5

const { errorAuth, successAuth } = trackEvents.auth

export const AuthProvider: React.FC<PropsType> = (props: PropsType) => {
  const dispatch = useAppDispatch()
  const { trackEvent } = useTracking()
  const { isAuthRunning, error, ssoToken } = useAppSelector(getAuthState)

  const isSSOExpired = (exp: number) =>
    Date.now() >= exp * 1000 - preventSSOExpiration

  useEffect(() => {
    const onSuccess = () => {
      trackEvent({ name: successAuth, payload: { isSuccessAuth: true } })
    }
    const onError = (authError: string) => {
      trackEvent({ name: errorAuth, payload: authError })
    }
    dispatch(authThunk({ isRefresh: false, onSuccess, onError }))
  }, [])

  useEffect(() => {
    if (ssoToken) {
      const { roles, tid }: JWTPayload = decodeJwt(ssoToken)

      if (roles) {
        const permissionsList = roles as Array<string>
        dispatch(setUserPermissionList(permissionsList))
      } else {
        dispatch(setUserPermissionList([]))
      }
      if (tid) {
        const tenantId = tid as string
        dispatch(setTenenatId(tenantId))
      }
    }
  }, [ssoToken])

  useEffect(() => {
    const interval = setInterval(() => {
      if (ssoToken) {
        const { exp } = decodeJwt(ssoToken)
        if (exp) {
          const isExpired = isSSOExpired(exp)
          if (isExpired) {
            dispatch(authThunk({ isRefresh: true }))
          }
        }
      }
    }, checkTokenExpirationInterval)
    return () => clearInterval(interval)
  }, [ssoToken, isSSOExpired, dispatch])

  return (
    <>
      {isAuthRunning && '...aвтентифікація'}
      {error && !isAuthRunning && !ssoToken && 'Помилка в ході aвтентифікації'}
      {!error && !isAuthRunning && ssoToken && props.children}
    </>
  )
}
