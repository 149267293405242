import { getPermissions } from '../redux/selectors/index'
import { useAppSelector } from '../redux/store'

type ReturnHookType = {
  getIsOperationAllowed: (neededPermissions: Array<string>) => boolean
}

export const usePermissions = (): ReturnHookType => {
  const userPermissions: Array<string> = useAppSelector(getPermissions)

  const getIsOperationAllowed = (neededPermissions: Array<string>) => {
    return neededPermissions.some((permission: string) =>
      userPermissions.includes(permission)
    )
  }

  return {
    getIsOperationAllowed,
  }
}
