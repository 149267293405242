import { string } from 'yup'
import {
  parsePhoneNumberFromString as parseMobile,
  validatePhoneNumberLength,
} from 'libphonenumber-js/mobile'
import { validation } from '../../../constants/forms/validation'

const { invalidPhoneNumber } = validation

const lengthValidation = {
  TO_SHORT: 'TO_SHORT',
  NOT_A_NUMBER: 'NOT_A_NUMBER',
}

export const getPhoneNumberSchema = (isRequired: boolean) => {
  let schema = string().test('phoneValidation', invalidPhoneNumber, (value) => {
    const phone = value ? value.replace(/ /g, '') : ''
    const lenghtValidationCode = validatePhoneNumberLength(phone, 'UA')
    if (lenghtValidationCode === lengthValidation.NOT_A_NUMBER) {
      return true
    }
    if (lenghtValidationCode === lengthValidation.TO_SHORT) {
      return false
    }
    const number = parseMobile(phone, 'UA')
    if (!number) return false
    return number.isValid()
  })

  schema = isRequired ? schema.required('required') : schema.notRequired()

  return schema
}
