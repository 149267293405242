import { formFieldsTypes } from '../../types/formFields.types'

export const createCommunitFields: formFieldsTypes = {
  communityName: {
    type: 'text',
    name: 'communityName',
    label: 'Назва громади',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  uniqueCommunityId: {
    type: 'text',
    name: 'uniqueCommunityId',
    label: 'ID  установи',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
}

export const filterCommunitiesList: formFieldsTypes = {
  search: {
    type: 'text',
    name: 'search',
    label: 'Пошук по ключовим словам',
    variant: 'outlined',
    required: false,
  },
}

export const editCommunitFields: formFieldsTypes = {
  communityName: {
    type: 'text',
    name: 'communityName',
    label: 'Назва громади',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  communityId: {
    type: 'text',
    name: 'communityId',
    label: 'Ідентифікатор громади',
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  region: {
    type: 'text',
    name: 'region',
    label: 'Ідентифікатор та назва керуючої установи',
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  sharedMailbox: {
    type: 'text',
    name: 'sharedMailbox',
    label: 'Email адреса',
    variant: 'outlined',
    required: false,
    disabled: true,
  },
}
