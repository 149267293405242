import React, { Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'
import {
  TextField,
  FormControl,
  SelectChangeEvent,
  AlertColor,
} from '@mui/material'
import { IpButton } from '../ui/IpButton'
import { formFieldTypes } from '../../types/formFields.types'

import { editSchoolFields } from '../../constants/forms/schools'
import { useFormData } from '../../hooks/useFormData'
import { useValidation } from '../../hooks/validation/useValidation'
import { SelectFormField } from '../formFields/SelectFormField'
import { usePermissions } from '../../hooks/usePermissions'
import { alertTypes, permissionResourcesKeys } from '../../constants/common'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { CreatedSchoolResponseType } from '../../types/schools.types'
import { useSearchCommunityField } from '../../hooks/useSearchCommunityField'
import { SearchAndSelectFormField } from '../formFields/SearchAndSelectFormField'
import { concatCommunityNameAndId } from '../../utils'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { getCommunities, getIsSchoolLoading } from '../../redux/selectors'
import {
  editSchoolData,
  editSchoolParent,
} from '../../redux/thunk/schoolsThunk'
import { addAlert } from '../../redux/slices/alertsList'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'
import { saveSearchValue } from '../../redux/slices/communitySlice'

type formikValuesTypes = {
  schoolName: string
  schoolId: string
  community: string
  sharedMailbox: string
  [key: string]: string
}

type PropsType = {
  initialSchoolData: CreatedSchoolResponseType
}

const { schoolsEdit, schoolsItadminEdit, schoolsSubordinate } =
  permissionResourcesKeys

const {
  errorEditSchoolParent,
  successEditSchoolParent,
  errorEditSchoolProfile,
  successEditSchoolProfile,
} = trackEvents.schools

export const EditSchoolForm: React.FC<PropsType> = (props: PropsType) => {
  const { getIsOperationAllowed } = usePermissions()
  const isLoading = useAppSelector(getIsSchoolLoading)
  const requiredFields = getIsOperationAllowed([schoolsItadminEdit])
    ? ['schoolName', 'community']
    : ['schoolName']

  const { formSchema } = useValidation(requiredFields)
  const dispatch = useAppDispatch()
  const { isDataChanged, checkIsDataChanged, isFormValid, checkIsFormValid } =
    useFormData()

  const { trackEvent } = useTracking()

  const {
    searchCommunitiesResult: { communities },
  } = useAppSelector(getCommunities)

  const isEditingAllowed = getIsOperationAllowed([
    schoolsEdit,
    schoolsItadminEdit,
  ])

  const editSchoolInitialData = {
    schoolName: props.initialSchoolData.name,
    schoolId: props.initialSchoolData.uniqueSchoolId,
    community: concatCommunityNameAndId(
      props.initialSchoolData.communityName || '',
      props.initialSchoolData.communityId
    ),
    sharedMailbox: props.initialSchoolData.sharedMailbox,
  }

  const getCommunityId = (community: string) => {
    return (
      communities.find(
        (item) =>
          concatCommunityNameAndId(item.name, item.uniqueCommunityId) ===
          community
      )?.uniqueCommunityId || ''
    )
  }

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    setFieldTouched,
    setErrors,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues: editSchoolInitialData,
    validationSchema: formSchema,
    onSubmit,
  })

  function onSubmit() {
    const { schoolName, community } = values
    const communityId = getCommunityId(community)
    if (schoolName !== editSchoolInitialData.schoolName) {
      updateSchoolData(schoolName)
    }
    if (
      getIsOperationAllowed([schoolsSubordinate]) &&
      props.initialSchoolData.communityId !== communityId
    ) {
      updateSchoolParent(communityId)
    }
  }

  function updateSchoolData(schoolName: string) {
    const payloadData = {
      body: {
        name: schoolName,
        schoolId: props.initialSchoolData.id,
      },
      onSuccessEditData,
      onErrorEditData,
    }
    dispatch(editSchoolData(payloadData))
  }

  function updateSchoolParent(communityId: string) {
    const payloadParent = {
      body: {
        schoolId: props.initialSchoolData.id,
        communityId,
      },
      onSuccessEditParentData,
      onErrorEditParentData,
    }
    dispatch(editSchoolParent(payloadParent))
  }

  function onSuccessEditParentData() {
    dispatch(saveSearchValue(''))
    trackEvent({ name: successEditSchoolParent, payload: values })
    dispatch(
      addAlert({
        id: 'editSchoolParentSuccess',
        type: alertTypes.success as AlertColor,
        text: 'Дані про навчальний заклад успішно оновлено',
      })
    )
  }

  function onSuccessEditData() {
    trackEvent({ name: successEditSchoolProfile, payload: values })
    dispatch(
      addAlert({
        id: 'editSchoolSuccess',
        type: alertTypes.success as AlertColor,
        text: 'Дані про навчальний заклад успішно оновлено',
      })
    )
  }

  function onErrorEditParentData(error: CatchErrorType) {
    trackEvent({ name: errorEditSchoolParent, payload: error })
  }

  function onErrorEditData(error: CatchErrorType) {
    trackEvent({ name: errorEditSchoolProfile, payload: error })
  }

  const {
    onSearch,
    onCommunityChange,
    communitiesOptions,
    areCommunitiesLoading,
  } = useSearchCommunityField({
    isTouched: touched.community,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setErrors,
    communityValue: values.community,
  })

  function changeSelectHandler(event: SelectChangeEvent<string>) {
    const { value, name } = event.target
    setFieldValue(name, value)
  }

  useEffect(() => {
    checkIsDataChanged(values, editSchoolInitialData)
  }, [values, editSchoolInitialData, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  function submit() {
    handleSubmit()
  }

  function renderTextField(fieldProps: formFieldTypes) {
    const validationError = touched[fieldProps.name] && errors[fieldProps.name]

    return (
      <div className="form-field">
        <FormControl fullWidth>
          <TextField
            {...fieldProps}
            value={values[fieldProps.name]}
            color="primary"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={validationError}
            error={!!validationError}
            disabled={
              fieldProps.disabled ||
              !getIsOperationAllowed([schoolsEdit]) ||
              isLoading
            }
          />
        </FormControl>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(editSchoolFields).map(
        (fieldProps: formFieldTypes, index) => {
          return (
            <Fragment key={index}>
              {fieldProps.type === 'text' &&
                fieldProps.name !== editSchoolFields.community.name &&
                renderTextField(fieldProps)}
              {fieldProps.type === 'text' &&
                fieldProps.name === editSchoolFields.community.name && (
                  <SearchAndSelectFormField
                    {...fieldProps}
                    options={communitiesOptions}
                    onBlurSearch={handleBlur}
                    onSearch={onSearch}
                    error={errors[fieldProps.name]}
                    touched={touched[fieldProps.name]}
                    value={values[fieldProps.name]}
                    freeSolo={!!values[fieldProps.name]?.length}
                    isLoading={areCommunitiesLoading}
                    noOptionsText="Почніть вводити перші літери назви громади, або цифри ідентифікатора громади"
                    loadingText="Триває пошук громад..."
                    onChange={onCommunityChange}
                    disabled={
                      !getIsOperationAllowed([schoolsSubordinate]) ||
                      fieldProps.disabled ||
                      isLoading
                    }
                  />
                )}
              {fieldProps.type === 'select' && (
                <SelectFormField
                  {...fieldProps}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={changeSelectHandler}
                  error={errors[fieldProps.name]}
                  value={values[fieldProps.name] as string}
                  disabled={
                    fieldProps.disabled || !isEditingAllowed || isLoading
                  }
                />
              )}
            </Fragment>
          )
        }
      )}

      <MandatoryFieldNotification />

      {isEditingAllowed && (
        <IpButton
          variant="contained"
          size="large"
          onClick={submit}
          disabled={!isFormValid || !isDataChanged || isLoading}
        >
          Зберегти
        </IpButton>
      )}
    </form>
  )
}
