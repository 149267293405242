import { tablesConfigTypes, TableHeadTypes } from '../../types/table.types'

export const userListTableConfig: TableHeadTypes = [
  {
    id: 'displayName',
    label: "Прізвище, ім'я та установа",
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'userIdNumber',
    label: 'Ідентифікуючий номер',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'userRole',
    label: '',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'userJobTitle',
    label: 'Посада',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'userPrincipalName',
    label: 'User name користувача',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'mfaStatus',
    label: 'Статус активації багатофакторної автентифікації',
    numeric: false,
    disablePadding: false,
  },
]

export const schoolsListTableConfig: TableHeadTypes = [
  {
    id: 'name',
    label: 'Назва установи',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'uniqueSchoolId',
    label: 'Ідентифікатор навчального закладу',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'hierarchicalLevel',
    label: 'Ідентифікатор керуючої установи',
    numeric: false,
    disablePadding: true,
  },
]

export const communityListTableConfig: TableHeadTypes = [
  {
    id: 'communityName',
    label: 'Назва громади',
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'uniqueCommunityId',
    label: 'Ідентифікатор громади',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'parentStructure',
    label: 'Область, якій підпорядковується',
    numeric: false,
    disablePadding: false,
  },
]

export const itAdminsTableConfig: TableHeadTypes = [
  {
    id: 'displayName',
    label: "Відображуване ім'я",
    numeric: false,
    disablePadding: true,
  },
  {
    id: 'userPrincipalName',
    label: 'User name користувача',
    numeric: false,
    disablePadding: true,
  },
]

export const tablesConfig: tablesConfigTypes = {
  usersList: userListTableConfig,
  schoolsList: schoolsListTableConfig,
  communityList: communityListTableConfig,
  itAdminsList: itAdminsTableConfig,
}
