import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import { FormikProps, useFormik } from 'formik'
import { SelectChangeEvent, AlertColor } from '@mui/material'

import { formFieldTypes, selectOptionTypes } from '../../types/formFields.types'
import { PhoneFormField } from '../formFields/PhoneFormField'
import {
  editUserFields,
  fieldsToAllowEditingByRoles,
  itAdminKey,
  userRoles,
} from '../../constants/forms/users'
import { userRequiredFields } from '../../constants/forms/validation'
import { useFormData } from '../../hooks/useFormData'
import { useValidation } from '../../hooks/validation/useValidation'
import {
  CreatedUserResponseType,
  EditUserThunkDataType,
} from '../../types/user.types'
import { TextFormField } from '../formFields/TextFormField'
import { SelectFormField } from '../formFields/SelectFormField'
import { CheckboxFormField } from '../formFields/CheckboxFormField'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { usePermissions } from '../../hooks/usePermissions'
import { alertTypes, permissionResourcesKeys } from '../../constants/common'
import { IpButton } from '../ui/IpButton'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { editUserData, editUserPhone } from '../../redux/thunk/usersThunk'
import { getRolesToCreate } from '../../redux/selectors'
import { addAlert } from '../../redux/slices/alertsList'
import { normalizePhone } from '../../utils'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'
import './index.scss'

type PropsType = {
  initialUserData: CreatedUserResponseType
}

type formikValuesTypes = {
  userIdNumber: string
  firstName: string
  lastName: string
  displayName: string
  userPrincipalName: string
  companyName: string
  userPhoneNumber: string
  userJobTitle: string
  employeeId: string
  userRole: string
  mfaStatus: string
  [key: string]: string
}

const { usersEdit, ITadminEdit } = permissionResourcesKeys

const {
  errorEditPhoneNumber,
  successEditPhoneNumber,
  errorEditUserProfile,
  successEditUserProfile,
} = trackEvents.users

export const EditUserForm: React.FC<PropsType> = ({
  initialUserData,
}: PropsType) => {
  const { formSchema } = useValidation(userRequiredFields)
  const [dataChanged, setWasDataChanged] = useState(false)
  const { getIsOperationAllowed } = usePermissions()
  const { isLoading } = useAppSelector((state) => state.users)
  const { checkIsFormValid, isFormValid } = useFormData()
  const isEditingAllowed = getIsOperationAllowed([usersEdit, ITadminEdit])
  const dispatch = useAppDispatch()
  const rolesToCreate = useAppSelector(getRolesToCreate)
  const { trackEvent } = useTracking()

  const [isItAdmin, setIsItAdmin] = useState(false)
  const {
    id,
    firstName,
    lastName,
    displayName,
    userPrincipalName,
    uniqueUserId,
    userRole,
    jobTitle,
    companyName,
    phoneNumber,
    mfaEnabled,
  } = initialUserData

  const editUserInitData: formikValuesTypes = useMemo(() => {
    return {
      userIdNumber: uniqueUserId,
      firstName,
      lastName,
      displayName,
      userPrincipalName,
      companyName: companyName || '',
      userPhoneNumber: phoneNumber || '',
      userJobTitle: jobTitle || '',
      employeeId: id,
      userRole: userRoles.find((role) => role.label === userRole)?.value || '',
      mfaStatus: mfaEnabled ? 'on' : 'off',
    }
  }, [
    id,
    firstName,
    lastName,
    displayName,
    userPrincipalName,
    uniqueUserId,
    userRole,
    jobTitle,
    companyName,
    phoneNumber,
    mfaEnabled,
  ])

  const wasPhoneChanged = (newPhone: string) => {
    return (
      normalizePhone(editUserInitData.userPhoneNumber || '') !==
      normalizePhone(newPhone)
    )
  }

  const wasUserDataChanged = (
    newData: formikValuesTypes | EditUserThunkDataType
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(newData).some(([key, value]) => {
      if (editUserInitData[key]) {
        return editUserInitData[key] !== value
      }
      if (key === 'userRole') {
        const newRole = userRoles.find((role) => role.label === value)?.value
        return editUserInitData.userRole !== newRole
      }
      if (key === 'jobTitle') {
        return editUserInitData.userJobTitle !== value
      }
      if (key === 'uniqueUserId') {
        return editUserInitData.userIdNumber !== value
      }
      if (key === 'mfaEnabled') {
        const isTurnedOn = value ? 'on' : 'off'
        return editUserInitData.mfaStatus !== isTurnedOn
      }
      return false
    })
  }

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    errors,
    touched,
    setValues,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues: editUserInitData,
    validationSchema: formSchema,

    onSubmit: () => {
      const dataToSubmit = {
        id: initialUserData.id,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.userJobTitle,
        mobilePhone: values.userPhoneNumber,
        ...(!isItAdmin && { userRole: values.userRole }),
        ...(!isItAdmin && { uniqueUserId: values.userIdNumber }),
        ...(!isItAdmin && { mfaEnabled: values.mfaStatus === 'on' }),
      }
      const payload = {
        data: dataToSubmit,
        onUserDataSuccess,
        onUserDataError,
        onPhoneSuccess,
        onPhoneError,
      }
      if (wasPhoneChanged(values.userPhoneNumber || '')) {
        dispatch(editUserPhone(payload))
      }
      if (wasUserDataChanged(dataToSubmit)) {
        dispatch(editUserData(payload))
      }
    },
  })

  function onUserDataSuccess() {
    trackEvent({ name: successEditUserProfile, payload: values })
    dispatch(
      addAlert({
        id: 'editUserDataSuccess',
        type: alertTypes.success as AlertColor,
        text: 'Відомості про користувача успішно оновлено',
      })
    )
  }

  function onPhoneSuccess() {
    trackEvent({ name: successEditPhoneNumber, payload: values })
    dispatch(
      addAlert({
        id: 'editPhoneErrorSuccess',
        type: alertTypes.success as AlertColor,
        text: 'Номер телефону користувача успішно оновлено',
      })
    )
  }

  function onUserDataError(error: CatchErrorType) {
    trackEvent({ name: errorEditUserProfile, payload: error })
  }

  function onPhoneError(error: CatchErrorType) {
    trackEvent({ name: errorEditPhoneNumber, payload: error })
  }

  const isFieldDisabledByRolePermissions = useCallback(
    (fieldName: string) => {
      if (isItAdmin) {
        return !fieldsToAllowEditingByRoles.itAdmin.includes(fieldName)
      }
      return !fieldsToAllowEditingByRoles.default.includes(fieldName)
    },
    [isItAdmin]
  )

  function changeSelectHandler(event: SelectChangeEvent<string>) {
    const { value, name } = event.target
    setFieldValue(name, value)
  }

  function changeCheckboxHandler(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target
    setFieldValue(name, value === 'on' ? 'off' : 'on')
  }

  const onChangePhone = (phoneValues: {
    floatValue: number | undefined
    formattedValue: string
    value: string
  }) => {
    const value = phoneValues.floatValue ? phoneValues.formattedValue : ''
    setFieldValue(editUserFields.userPhoneNumber.name, value)
    if (!value && touched[editUserFields.userPhoneNumber.name]) {
      setFieldTouched(editUserFields.userPhoneNumber.name, false)
    }
  }

  useEffect(() => {
    const userDataChanged = wasUserDataChanged(values)
    const phoneChanged = wasPhoneChanged(values.userPhoneNumber || '')
    setWasDataChanged(userDataChanged || phoneChanged)
  }, [values, wasUserDataChanged])

  useEffect(() => {
    setValues(editUserInitData)
  }, [editUserInitData])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  useEffect(() => {
    const role = userRoles.find((roleItem) => {
      return (
        initialUserData.userRole &&
        roleItem.label.toLowerCase() === initialUserData.userRole.toLowerCase()
      )
    })
    if (role && role.value === itAdminKey) {
      setIsItAdmin(true)
    }
  }, [initialUserData])

  const filterRoleOptions = useCallback(
    (options: Array<selectOptionTypes>) => {
      return options
        .filter(
          ({ value }) =>
            rolesToCreate.includes(value) || (isItAdmin && value === itAdminKey)
        )
        .map((option) => ({
          ...option,
          disabled: option.value === itAdminKey,
        }))
    },
    [isItAdmin]
  )

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(editUserFields).map(
        (fieldProps: formFieldTypes, index) => {
          const isPhoneField =
            fieldProps.name === editUserFields.userPhoneNumber.name
          return (
            <Fragment key={index}>
              {fieldProps.type === 'text' && !isPhoneField && (
                <TextFormField
                  {...fieldProps}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={errors[fieldProps.name]}
                  value={values[fieldProps.name]}
                  disabled={
                    fieldProps.disabled ||
                    !isEditingAllowed ||
                    isFieldDisabledByRolePermissions(fieldProps.name) ||
                    isLoading
                  }
                />
              )}
              {fieldProps.type === 'select' && (
                <SelectFormField
                  {...fieldProps}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={changeSelectHandler}
                  error={errors[fieldProps.name]}
                  value={values[fieldProps.name]}
                  options={
                    fieldProps.options && filterRoleOptions(fieldProps.options)
                  }
                  disabled={
                    fieldProps.disabled ||
                    !isEditingAllowed ||
                    isFieldDisabledByRolePermissions(fieldProps.name) ||
                    isLoading
                  }
                />
              )}
              {fieldProps.type === 'checkbox' && (
                <CheckboxFormField
                  {...fieldProps}
                  value={values[fieldProps.name]}
                  checked={values[fieldProps.name] === 'on'}
                  handleChange={changeCheckboxHandler}
                  handleBlur={handleBlur}
                  disabled={
                    fieldProps.disabled ||
                    !isEditingAllowed ||
                    isFieldDisabledByRolePermissions(fieldProps.name) ||
                    isLoading
                  }
                />
              )}
              {fieldProps.type === 'text' && isPhoneField && (
                <PhoneFormField
                  {...fieldProps}
                  value={values[fieldProps.name]?.replace('+380', '')}
                  onChangePhone={onChangePhone}
                  error={errors[fieldProps.name]}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  disabled={
                    fieldProps.disabled ||
                    !isEditingAllowed ||
                    isFieldDisabledByRolePermissions(fieldProps.name) ||
                    isLoading
                  }
                />
              )}
            </Fragment>
          )
        }
      )}

      {isEditingAllowed && (
        <>
          <MandatoryFieldNotification />
          <IpButton
            variant="contained"
            size="large"
            type="submit"
            disabled={!isFormValid || !dataChanged || isLoading}
          >
            {isLoading ? 'Збереження...' : 'Зберегти'}
          </IpButton>
        </>
      )}
    </form>
  )
}
