import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { selectOptionTypes } from '../../../types/formFields.types'

import './index.scss'

type PropsType = {
  type: string
  name: string
  label: string
  variant?: 'outlined'
  required: boolean
  options?: Array<selectOptionTypes>
  placeholder?: string
  disabled?: boolean
  checked?: boolean
  value?: string
  touched?: boolean
  isLoading?: boolean
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement & HTMLLIElement>
  ) => void
  handleChange: (event: SelectChangeEvent<string>) => void
  error?: string
}

export const SelectFormField: React.FC<PropsType> = ({
  touched,
  error,
  handleBlur,
  handleChange,
  isLoading,
  value,
  ...fieldProps
}: PropsType) => {
  const validationError = touched && error
  return (
    <div className="form-field">
      <FormControl fullWidth>
        <InputLabel
          id="demo-simple-select-helper-label"
          className={fieldProps.disabled ? 'disabled' : ''}
        >
          {fieldProps.label}
          {fieldProps.required ? '*' : ''}
        </InputLabel>

        <Select
          labelId={fieldProps.name}
          id={fieldProps.name}
          value={value}
          label={fieldProps.label}
          color="primary"
          name={fieldProps.name}
          error={!!validationError}
          disabled={fieldProps.disabled || isLoading}
          onChange={handleChange}
        >
          {fieldProps.options &&
            fieldProps.options.map((option: selectOptionTypes) => {
              return (
                <MenuItem
                  disabled={option.disabled}
                  key={option.value}
                  value={option.value}
                  onBlur={(event) => {
                    const typedEvent = event as React.FocusEvent<
                      HTMLInputElement & HTMLLIElement
                    >
                    handleBlur(typedEvent)
                  }}
                >
                  {option.label}
                </MenuItem>
              )
            })}
        </Select>
        <FormHelperText color="error">{validationError}</FormHelperText>
      </FormControl>
    </div>
  )
}
