import colors from '../colors'

export default {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        '&.MuiCheckbox-colorPrimary, &.MuiCheckbox-colorPrimary + .MuiTypography-root':
          {
            color: colors.primary.main,
          },
        '&.MuiCheckbox-colorSecondary, &.MuiCheckbox-colorSecondary + .MuiTypography-root':
          {
            color: colors.secondary.main,
          },
        '&.MuiCheckbox-colorSuccess, &.MuiCheckbox-colorSuccess + .MuiTypography-root':
          {
            color: colors.success.main,
          },
        '&.MuiCheckbox-colorError, &.MuiCheckbox-colorError + .MuiTypography-root':
          {
            color: colors.error.main,
          },
        '& + .MuiTypography-root': {
          fontWeight: 700,
          marginLeft: 7,
          marginBottom: 2,
        },
        '&.Mui-disabled.Mui-checked, &.Mui-disabled + .MuiTypography-root': {
          color: colors.grey['800'],
        },
        '&.Mui-disabled': {
          color: colors.grey['600'],
        },
      },
    },
  },
}
