import React from 'react'
import { Container } from '@mui/material'
import { useSelector } from 'react-redux'
import NavBar from '../NavBar'
import { CustomAlert } from '../CustomAlert'
import { getAlerts } from '../../redux/selectors'
import { RootState } from '../../redux/store'
import { AlertType } from '../../redux/slices/alertsList'

type PageLayoutTypes = {
  children: React.ReactNode
}

export const PageLayout: React.FC<PageLayoutTypes> = (
  props: PageLayoutTypes
) => {
  const alerts = useSelector<RootState, AlertType[]>(getAlerts)
  return (
    <>
      {alerts.map((alertItem: AlertType, index: number) => (
        <CustomAlert key={alertItem.id} {...alertItem} index={index} />
      ))}

      <NavBar />
      <article className="page">
        <Container maxWidth="xl">{props.children}</Container>
      </article>
    </>
  )
}
