import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const { notMatchCommunitySearch, communitySearchRegexp } = validation

export const getSearchCommunitySchema = (isRequired: boolean) => {
  let schema = string().matches(communitySearchRegexp, notMatchCommunitySearch)

  schema = isRequired ? schema.required('') : schema.notRequired()

  return schema
}
