import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FilterFields } from '../../types/filter.types'
import {
  SchoolsTransformResponseType,
  CreatedSchoolResponseType,
  SchoolsSliceType,
} from '../../types/schools.types'

import {
  createSchool,
  fetchSchoolById,
  editSchoolData,
  fetchSchools,
  editSchoolParent,
} from '../thunk/schoolsThunk'

const initialState: SchoolsSliceType = {
  data: { currentPage: [], skipToken: '', pageSize: 5 },
  currentSchool: {
    id: '',
    uniqueSchoolId: '',
    name: '',
    communityId: '',
    regionId: '',
    numberOfMembers: 0,
    sharedMailbox: '',
    communityName: '',
    itAdmins: [],
  },
  filter: { orderBy: 'uniqueSchoolId', orderDir: 'asc' },
  isLoading: false,
  error: '',
}

export const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    loadMoreSchools: (
      state,
      { payload }: PayloadAction<SchoolsTransformResponseType>
    ) => {
      state.data.currentPage = [
        ...state.data.currentPage,
        ...payload.currentPage,
      ]
      state.data.skipToken = payload.skipToken
      state.isLoading = false
    },
    firstSchoolsLoad: (
      state,
      { payload }: PayloadAction<SchoolsTransformResponseType>
    ) => {
      state.data = payload
      state.isLoading = false
    },
    updateSchoolsFilter: (state, { payload }: PayloadAction<FilterFields>) => {
      state.filter = payload
    },
    fetchSchool: (
      state,
      { payload }: PayloadAction<CreatedSchoolResponseType>
    ) => {
      state.currentSchool = payload
    },
    updateSchoolName: (state, { payload }: PayloadAction<string>) => {
      state.currentSchool.name = payload
    },
    updateSchoolParent: (
      state,
      { payload }: PayloadAction<{ communityId: string; name: string }>
    ) => {
      state.currentSchool.communityId = payload.communityId
      state.currentSchool.communityName = payload.name
    },
  },
  extraReducers: {
    [fetchSchoolById.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchSchoolById.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [fetchSchoolById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createSchool.pending.type]: (state) => {
      state.isLoading = true
    },
    [createSchool.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [createSchool.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchSchools.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchSchools.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [fetchSchools.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [editSchoolData.pending.type]: (state) => {
      state.isLoading = true
    },
    [editSchoolData.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [editSchoolData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [editSchoolParent.pending.type]: (state) => {
      state.isLoading = true
    },
    [editSchoolParent.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [editSchoolParent.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  loadMoreSchools,
  firstSchoolsLoad,
  updateSchoolsFilter,
  fetchSchool,
  updateSchoolName,
  updateSchoolParent,
} = schoolsSlice.actions

export default schoolsSlice.reducer
