import React from 'react'
import { IpText } from '../../components/ui/IpText'
import { EditUserForm } from '../../components/EditUserForm'
import { useAppSelector } from '../../redux/store'
import { getCurrentUser } from '../../redux/selectors'
import { BackBtn } from '../../components/BackBtn'

export const UserProfile: React.FC = () => {
  const currentUser = useAppSelector(getCurrentUser)

  return (
    <>
      <BackBtn />
      <IpText variant="h4">Профіль користувача</IpText>
      <EditUserForm initialUserData={currentUser} />
    </>
  )
}
