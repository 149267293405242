import React, { Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'
import {
  TextField,
  FormControl,
  SelectChangeEvent,
  AlertColor,
} from '@mui/material'
import { IpButton } from '../ui/IpButton'
import { formFieldTypes } from '../../types/formFields.types'

import { useFormData } from '../../hooks/useFormData'
import { useValidation } from '../../hooks/validation/useValidation'
import { SelectFormField } from '../formFields/SelectFormField'
import { usePermissions } from '../../hooks/usePermissions'
import { alertTypes, permissionResourcesKeys } from '../../constants/common'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { CurrentCommunityType } from '../../types/communities.types'
import { editCommunitFields } from '../../constants/forms/community'
import { addAlert } from '../../redux/slices/alertsList'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { editCommunity } from '../../redux/thunk/communitiesThunk'
import { getCommunities } from '../../redux/selectors'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'

type formikValuesTypes = {
  communityName: string
  communityId: string
  region: string
  sharedMailbox: string
  [key: string]: string
}

type PropsType = {
  initialCommunityData: CurrentCommunityType
}

const { communitiesEdit, communitiesItadminEdit } = permissionResourcesKeys

const { errorEditCommunity, successEditCommunity } = trackEvents.communities

export const EditCommunityForm: React.FC<PropsType> = (props: PropsType) => {
  const dispatch = useAppDispatch()
  const { getIsOperationAllowed } = usePermissions()
  const { isLoading } = useAppSelector(getCommunities)
  const { formSchema } = useValidation([editCommunitFields.communityName.name])
  const { isDataChanged, checkIsDataChanged, isFormValid, checkIsFormValid } =
    useFormData()

  const { trackEvent } = useTracking()

  const isEditingAllowed = getIsOperationAllowed([
    communitiesEdit,
    communitiesItadminEdit,
  ])

  const initialValues = {
    communityName: props.initialCommunityData.name,
    communityId: props.initialCommunityData.uniqueCommunityId,
    region: `(${props.initialCommunityData.regionId}) ${props.initialCommunityData.regionName}`,
    sharedMailbox: props.initialCommunityData.sharedMailbox,
  }

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues,
    validationSchema: formSchema,
    onSubmit,
  })

  function onSubmit() {
    const payload = {
      body: {
        id: props.initialCommunityData.id,
        name: values.communityName,
      },
      onSuccess,
      onError,
    }
    dispatch(editCommunity(payload))
  }

  function onSuccess() {
    trackEvent({ name: successEditCommunity, payload: values })
    dispatch(
      addAlert({
        id: 'communityEditSuccess',
        type: alertTypes.success as AlertColor,
        text: 'Відомості про громаду успішно оновлено',
      })
    )
  }

  function onError(error: CatchErrorType) {
    trackEvent({ name: errorEditCommunity, payload: error })
  }

  function changeSelectHandler(event: SelectChangeEvent<string>) {
    const { value, name } = event.target
    setFieldValue(name, value)
  }

  useEffect(() => {
    checkIsDataChanged(values, initialValues)
  }, [values, initialValues, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  function renderTextField(fieldProps: formFieldTypes) {
    const validationError = touched[fieldProps.name] && errors[fieldProps.name]

    return (
      <div className="form-field">
        <FormControl fullWidth>
          <TextField
            {...fieldProps}
            value={values[fieldProps.name]}
            color="primary"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={validationError}
            error={!!validationError}
            disabled={fieldProps.disabled || !isEditingAllowed || isLoading}
          />
        </FormControl>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(editCommunitFields).map(
        (fieldProps: formFieldTypes, index) => {
          return (
            <Fragment key={index}>
              {fieldProps.type === 'text' && renderTextField(fieldProps)}
              {fieldProps.type === 'select' && (
                <SelectFormField
                  {...fieldProps}
                  touched={touched[fieldProps.name]}
                  handleBlur={handleBlur}
                  handleChange={changeSelectHandler}
                  error={errors[fieldProps.name]}
                  value={values[fieldProps.name] as string}
                  disabled={
                    fieldProps.disabled || !isEditingAllowed || isLoading
                  }
                />
              )}
            </Fragment>
          )
        }
      )}

      <MandatoryFieldNotification />

      {isEditingAllowed && (
        <IpButton
          variant="contained"
          size="large"
          type="submit"
          disabled={!isFormValid || !isDataChanged || isLoading}
        >
          Зберегти
        </IpButton>
      )}
    </form>
  )
}
