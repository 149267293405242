import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const { maxSearch, maxLengthSearch, notMatchSearch, searchRegexp } = validation

export const getSearchSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxSearch, maxLengthSearch)
    .matches(searchRegexp, notMatchSearch)

  schema = isRequired ? schema.required('') : schema.notRequired()

  return schema
}
