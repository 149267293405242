import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsLastName,
  maxLengthLastName,
  requiredLastName,
  invalidSymbolLastName,
  nameValidation,
} = validation

export const getLastNameSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsLastName, maxLengthLastName)
    .matches(nameValidation, invalidSymbolLastName)

  schema = isRequired ? schema.required(requiredLastName) : schema.notRequired()

  return schema
}
