import React from 'react'
import { FormControl, TextField } from '@mui/material'
import { selectOptionTypes } from '../../../types/formFields.types'

type PropsType = {
  type: string
  name: string
  label: string
  variant?: 'outlined'
  required: boolean
  options?: Array<selectOptionTypes>
  placeholder?: string
  disabled?: boolean
  checked?: boolean
  value?: string
  touched?: boolean
  isLoading?: boolean
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export const TextFormField: React.FC<PropsType> = ({
  touched,
  error,
  handleBlur,
  handleChange,
  isLoading,
  ...fieldProps
}: PropsType) => {
  const validationError = touched && error

  return (
    <div className="form-field">
      <FormControl fullWidth>
        <TextField
          {...fieldProps}
          color="primary"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={validationError}
          error={!!validationError}
          disabled={fieldProps.disabled || isLoading}
        />
      </FormControl>
    </div>
  )
}
