import React, { Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'

import { useNavigate } from 'react-router-dom'
import { IpButton } from '../ui/IpButton'
import { formFieldTypes } from '../../types/formFields.types'
import { createSchoolFields } from '../../constants/forms/schools'
import { schoolRequiredFields } from '../../constants/forms/validation'
import { useValidation } from '../../hooks/validation/useValidation'
import { useFormData } from '../../hooks/useFormData'

import { CatchErrorType } from '../../types/redux'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { createSchool } from '../../redux/thunk/schoolsThunk'
import { TextFormField } from '../formFields/TextFormField'
import { MandatoryFieldNotification } from '../MandatoryFieldNotification'
import { itAdminsLevels } from '../../constants/common'
import { getCommunities, infoPageSelector } from '../../redux/selectors'
import { SearchAndSelectFormField } from '../formFields/SearchAndSelectFormField'
import { concatCommunityNameAndId } from '../../utils'
import { useSearchCommunityField } from '../../hooks/useSearchCommunityField'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { routesConfiguration } from '../../routing'
import { saveSearchValue } from '../../redux/slices/communitySlice'
import './index.scss'

type formikValuesTypes = {
  schoolName: string
  schoolId: string
  community: string
  [key: string]: string
}

const { successCreateSchool, errorСreateSchool } = trackEvents.schools

export const CreateSchoolForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    data: { level: adminLevel },
  } = useAppSelector(infoPageSelector)
  const isRegionAdmin = itAdminsLevels.region === adminLevel.toLowerCase()
  const requiredFields = isRegionAdmin
    ? [...schoolRequiredFields, 'community']
    : schoolRequiredFields
  const { formSchema } = useValidation(requiredFields)
  const { isDataChanged, checkIsDataChanged, isFormValid, checkIsFormValid } =
    useFormData()

  const { trackEvent } = useTracking()

  const { isLoading } = useAppSelector((state) => state.schools)
  const {
    searchCommunitiesResult: { communities },
  } = useAppSelector(getCommunities)

  const createSchoolInitialData = {
    schoolName: '',
    schoolId: '',
    community: '',
  }

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setFieldTouched,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues: createSchoolInitialData,

    validationSchema: formSchema,

    onSubmit: () => {
      const { schoolName, schoolId, community } = values
      const communityId = communities.find(
        (item) =>
          concatCommunityNameAndId(item.name, item.uniqueCommunityId) ===
          community
      )?.uniqueCommunityId
      const body = {
        uniqueSchoolId: schoolId,
        name: schoolName,
        ...(isRegionAdmin && { communityId }),
      }
      const payload = {
        body,
        onSuccess: () => {
          dispatch(saveSearchValue(''))
          trackEvent({ name: successCreateSchool, payload: body })
          navigate(routesConfiguration.successCreateSchool.path, {
            state: body,
          })
        },
        onError: (error: CatchErrorType) => {
          trackEvent({ name: errorСreateSchool, payload: error })
        },
      }
      dispatch(createSchool(payload))
    },
  })

  const {
    onSearch,
    onCommunityChange,
    communitiesOptions,
    areCommunitiesLoading,
    isCommunityValueValid,
  } = useSearchCommunityField({
    isTouched: touched.community,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setErrors,
    communityValue: values.community,
  })

  useEffect(() => {
    checkIsDataChanged(values, createSchoolInitialData)
  }, [values, createSchoolInitialData, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(createSchoolFields).map(
        (fieldProps: formFieldTypes, index) => {
          if (fieldProps.name === 'community' && isRegionAdmin) {
            return (
              <Fragment key={index}>
                <SearchAndSelectFormField
                  {...fieldProps}
                  options={communitiesOptions}
                  onBlurSearch={handleBlur}
                  onSearch={onSearch}
                  error={errors[fieldProps.name]}
                  touched={touched[fieldProps.name]}
                  value={values[fieldProps.name]}
                  freeSolo={!!values[fieldProps.name].length}
                  isLoading={areCommunitiesLoading}
                  noOptionsText="Почніть вводити перші літери назви громади, або цифри ідентифікатора громади"
                  loadingText="Триває пошук громад..."
                  onChange={onCommunityChange}
                />
              </Fragment>
            )
          }
          if (fieldProps.name !== 'community') {
            return (
              <Fragment key={index}>
                <TextFormField
                  {...fieldProps}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors[fieldProps.name]}
                  touched={touched[fieldProps.name]}
                  isLoading={isLoading}
                />
              </Fragment>
            )
          }
          return null
        }
      )}

      <MandatoryFieldNotification />

      <IpButton
        variant="contained"
        size="large"
        type="submit"
        disabled={
          !isFormValid ||
          !isDataChanged ||
          isLoading ||
          (isRegionAdmin && isCommunityValueValid)
        }
      >
        {isLoading ? 'Створення...' : 'Створити'}
      </IpButton>
    </form>
  )
}
