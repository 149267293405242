import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxUniqueCommunityId,
  maxLengthUniqueCommunityId,
  requiredUniqueCommunityId,
  uniqueCommunityIdRegexp,
  notMatchUniqueCommunityId,
} = validation

export const getUniqueCommunityIdSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxUniqueCommunityId, maxLengthUniqueCommunityId)
    .matches(uniqueCommunityIdRegexp, notMatchUniqueCommunityId)

  schema = isRequired
    ? schema.required(requiredUniqueCommunityId)
    : schema.notRequired()

  return schema
}
