import React from 'react'
import { IpText } from '../ui/IpText'

type PropsType = {
  message?: string
}

export const NotFundNotification: React.FC<PropsType> = (props: PropsType) => {
  return (
    <IpText variant="body1" classes="no-table-data" align="center">
      {props.message}
    </IpText>
  )
}
