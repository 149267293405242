export default {
  MuiBadge: {
    styleOverrides: {
      root: {
        '& .MuiBadge-badge:not(.MuiBadge-dot)': {
          minWidth: '14px',
          height: '14px',
          fontWeight: 700,
          fontSize: '9px',
          lineHeight: '11px',
          padding: '0 4px',
        },
        '& .MuiBadge-badge--centered': {
          top: '50%',
          zIndex: 2,
        },
      },
    },
  },
}
