import React from 'react'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { CreateUserForm } from '../../components/CreateUserForm'

export const CreateUser: React.FC = () => {
  return (
    <>
      <BackBtn />
      <IpText variant="h4">Створити нового користувача</IpText>
      <CreateUserForm />
    </>
  )
}
