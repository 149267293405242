import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { IpButton } from '../../components/ui/IpButton'
import { routesConfiguration } from '../../routing'
import { CreatedUserResponseType } from '../../types/user.types'
import { usePermissions } from '../../hooks/usePermissions'
import { permissionResourcesKeys } from '../../constants/common'
import './index.scss'

const { usersList, ITadminEdit } = permissionResourcesKeys

export const SuccessCreateUser: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getIsOperationAllowed } = usePermissions()
  const locationState = location.state as CreatedUserResponseType

  useEffect(() => {
    if (!locationState) navigate(routesConfiguration.createUser.path)
  }, [locationState])

  function toCreateUserPage() {
    navigate(routesConfiguration.createUser.path)
  }

  return (
    <>
      <article className="success-create-user">
        <BackBtn
          path={routesConfiguration.usersList.path}
          text="Повернутись до списку користувачів"
        />
        <IpText variant="h4">
          Вітаємо! Нового користувача успішно створено!
        </IpText>
        {locationState?.displayName && (
          <IpText paragraph={true}>
            Користувач: {locationState.displayName}
          </IpText>
        )}
        {locationState?.userPrincipalName && (
          <IpText paragraph={true}>
            User name користувача: {locationState.userPrincipalName}
          </IpText>
        )}
        {getIsOperationAllowed([usersList, ITadminEdit]) && (
          <div className="buttons-wrapper">
            <IpButton
              variant="contained"
              size="large"
              onClick={toCreateUserPage}
            >
              Створити нового користувача
            </IpButton>
          </div>
        )}
      </article>
    </>
  )
}
