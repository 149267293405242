import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { IpButton } from '../../components/ui/IpButton'
import { routesConfiguration } from '../../routing'
import { CreateSchoolBodyType } from '../../types/schools.types'
import { usePermissions } from '../../hooks/usePermissions'
import { permissionResourcesKeys } from '../../constants/common'

const { schoolsCreate } = permissionResourcesKeys

export const SuccessCreateSchool: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getIsOperationAllowed } = usePermissions()
  const locationState = location.state as CreateSchoolBodyType

  useEffect(() => {
    if (!locationState) toCreateSchoolPage()
  }, [locationState])

  function toCreateSchoolPage() {
    navigate(routesConfiguration.createSchool.path)
  }

  return (
    <>
      <article className="success-create-community">
        <BackBtn
          path={routesConfiguration.schoolList.path}
          text="Повернутись до списку навчальних закладів"
        />
        <IpText variant="h4">Вітаємо! Новий навчальний заклад створено!</IpText>
        {locationState?.name && (
          <IpText paragraph={true}>
            Назва навчального закладу: {locationState.name}
          </IpText>
        )}
        {locationState?.uniqueSchoolId && (
          <IpText paragraph={true}>
            Ідентифікатор навчального закладу: {locationState.uniqueSchoolId}
          </IpText>
        )}
        {getIsOperationAllowed([schoolsCreate]) && (
          <div className="buttons-wrapper">
            <IpButton
              variant="contained"
              size="large"
              onClick={toCreateSchoolPage}
            >
              Створити новий навчальний заклад
            </IpButton>
          </div>
        )}
      </article>
    </>
  )
}
