import colors from '../colors'

// TODO move it somewhere when we decide how we work with responsiveness
const isTouchScreen =
  '@media only screen and (hover: none) and (pointer: coarse)'

/*
  specific conditions for outlined buttons connected with sliding animation.
  To implement it :before was used. Border radius and overflow: hidden are
  the reason for browsers' issue with white spaces between border and inner
  element. That's why the border was changed to outline and that's why there
  are differences in button min sizes
*/
export default {
  MuiButton: {
    variants: [
      {
        props: { size: 'extraSmall' },
        style: {
          fontSize: '11px',
          minWidth: '130px',
          minHeight: '20px',
          padding: 0,
        },
      },
      {
        props: { size: 'extraSmall', variant: 'outlined' },
        style: {
          minWidth: '128px',
          minHeight: '18px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          minWidth: '130px',
          minHeight: '25px',
          fontSize: '13px',
        },
      },
      {
        props: { size: 'small', variant: 'outlined' },
        style: {
          minWidth: '128px',
          minHeight: '23px',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          minWidth: '150px',
          minHeight: '30px',
          fontSize: '15px',
        },
      },
      {
        props: { size: 'medium', variant: 'outlined' },
        style: {
          minWidth: '148px',
          minHeight: '28px',
        },
      },
      {
        props: { size: 'large' },
        style: {
          minWidth: '180px',
          minHeight: '46px',
          fontSize: '18px',
        },
      },
      {
        props: { size: 'large', variant: 'outlined' },
        style: {
          minWidth: '178px',
          minHeight: '44px',
        },
      },
    ],
    styleOverrides: {
      root: {
        overflow: 'hidden',
        borderRadius: 6,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          '&:before': {
            width: '100%',
            [isTouchScreen]: {
              width: 0,
            },
          },
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          width: 0,
          height: '100%',
          transition: 'width 0.3s',
          borderRadius: 6,
        },
        '.button-content': {
          position: 'relative',
          zIndex: 2,
        },
        '&.MuiButton': {
          'a&-text': {
            textDecoration: 'underline',
          },
          '&-text': {
            padding: 0,
            minWidth: 0,
            minHeight: 0,
          },
          '&-containedPrimary': {
            '&:hover': {
              backgroundColor: colors.primary.main,
            },
            '&:before': {
              backgroundColor: colors.primary.dark,
            },
          },
          '&-containedSecondary': {
            color: colors.text.secondary,
            '&:hover': {
              backgroundColor: colors.secondary.main,
              color: colors.text.secondary,
            },
            '&:before': {
              backgroundColor: colors.secondary.dark,
            },
          },
          '&-containedSuccess, &-containedError': {
            color: colors.text.secondary,
          },
          '&-outlinedPrimary, &-outlinedSecondary': {
            backgroundColor: colors.background.default,
            border: 0,
            outlineWidth: 1,
            outlineStyle: 'solid',
            transition: 'outline-color 0.3s',
          },
          '&-outlinedPrimary': {
            outlineColor: colors.primary.main,
            '&:hover': {
              color: colors.text.secondary,
              outlineColor: colors.primary.dark,
              [isTouchScreen]: {
                color: colors.primary.main,
              },
            },
            '&:before': {
              backgroundColor: colors.primary.dark,
            },
          },
          '&-outlinedSecondary': {
            outlineColor: colors.secondary.main,
            '&:hover': {
              color: colors.text.primary,
              outlineColor: colors.secondary.dark,
              [isTouchScreen]: {
                color: colors.secondary.main,
              },
            },
            '&:before': {
              backgroundColor: colors.secondary.dark,
            },
          },
        },
        '&.Mui-disabled': {
          backgroundColor: colors.grey['600'],
          color: colors.grey['800'],
          '&.MuiButton-outlined': {
            backgroundColor: colors.background.default,
          },
        },
        '& .MuiTouchRipple-root': {
          zIndex: 1,
        },
      },
    },
  },
}
