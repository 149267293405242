/* eslint-disable no-param-reassign */
import { AlertColor } from '@mui/material'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AlertType = {
  id: string
  type: AlertColor
  text: string
}

export type AlertSlice = {
  alertsList: Array<AlertType>
}

const initialState: AlertSlice = {
  alertsList: [],
}

export const alertSlice = createSlice({
  name: 'alertsList',
  initialState,
  reducers: {
    addAlert: (state, { payload }: PayloadAction<AlertType>) => {
      state.alertsList = [...state.alertsList, payload]
    },
    deleteAlert: (state, { payload }: PayloadAction<string>) => {
      const newAlertsList = state.alertsList.filter(
        (alertItem) => alertItem.id !== payload
      )
      state.alertsList = newAlertsList
    },
  },
})

export const { addAlert, deleteAlert } = alertSlice.actions

export const { reducer: alertsList } = alertSlice
