import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { IpButton } from '../../components/ui/IpButton'
import { routesConfiguration } from '../../routing'
import { CreateCommunityBodyType } from '../../types/communities.types'
import { usePermissions } from '../../hooks/usePermissions'
import { permissionResourcesKeys } from '../../constants/common'

const { communitiesCreate } = permissionResourcesKeys

export const SuccessCreateCommunity: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getIsOperationAllowed } = usePermissions()
  const locationState = location.state as CreateCommunityBodyType

  useEffect(() => {
    if (!locationState) toCreateCommunityPage()
  }, [locationState])

  function toCreateCommunityPage() {
    navigate(routesConfiguration.createCommunity.path)
  }

  return (
    <>
      <article className="success-create-community">
        <BackBtn
          path={routesConfiguration.communityList.path}
          text="Повернутись до списку громад"
        />
        <IpText variant="h4">Вітаємо! Нову громаду успішно створено!</IpText>
        {locationState?.name && (
          <IpText paragraph={true}>Назва громади: {locationState.name}</IpText>
        )}
        {locationState?.uniqueCommunityId && (
          <IpText paragraph={true}>
            Ідентифікатор громади: {locationState.uniqueCommunityId}
          </IpText>
        )}
        {getIsOperationAllowed([communitiesCreate]) && (
          <div className="buttons-wrapper">
            <IpButton
              variant="contained"
              size="large"
              onClick={toCreateCommunityPage}
            >
              Створити нову громаду
            </IpButton>
          </div>
        )}
      </article>
    </>
  )
}
