import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type PermissionType = Array<string>

export type PermissionsType = {
  permissionsList: PermissionType
}

const initialState: PermissionsType = {
  permissionsList: [],
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setUserPermissionList: (state, { payload }: PayloadAction<string[]>) => {
      state.permissionsList = payload
    },
  },
})

export const { setUserPermissionList } = permissionsSlice.actions

export const { reducer: permissions } = permissionsSlice
