import React, { ReactNode, MouseEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { IpIconButtonProps } from '../../../types/ui/IpIconButton.types'
import { DEFAULT_SVG_ICON_SIZE } from '../../../constants/ui.constants'

type IpIconButtonPropsReact = IpIconButtonProps & {
  onClick?: (event: MouseEvent<HTMLElement>) => void
  children: ReactNode
}

const IpIconButtonStyled = styled('div')(
  ({ size }: IpIconButtonPropsReact) => ({
    '& .MuiButtonBase-root': { padding: 0 },
    '& .MuiSvgIcon-root': { fontSize: `${size}px` },
  })
)

export function IpIconButton(props: IpIconButtonPropsReact) {
  const {
    color,
    disabled,
    edge,
    size = DEFAULT_SVG_ICON_SIZE,
    classes,
    onClick,
    children,
  } = props

  return (
    <IpIconButtonStyled size={size}>
      <IconButton
        color={color}
        disabled={disabled}
        edge={edge}
        onClick={onClick}
        classes={{ root: classes }}
      >
        {children}
      </IconButton>
    </IpIconButtonStyled>
  )
}
