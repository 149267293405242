import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitStateType = {
  ssoToken: string
  tenantId: string
  isAuthRunning: boolean
  error: boolean
  sessionId: null | string
}

const initialState: InitStateType = {
  sessionId: null,
  ssoToken: '',
  isAuthRunning: false,
  error: false,
  tenantId: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStart: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthRunning = payload
    },
    setSSOToken: (state, { payload }: PayloadAction<string>) => {
      state.ssoToken = payload
      state.isAuthRunning = false
    },
    setSSOError: (state, { payload }: PayloadAction<unknown>) => {
      state.error = !!payload
      state.isAuthRunning = false
      state.ssoToken = ''
    },
    setSessionId: (state, { payload }: PayloadAction<string>) => {
      state.sessionId = payload
    },
    setTenenatId: (state, { payload }: PayloadAction<string>) => {
      state.tenantId = payload
    },
  },
})
export const {
  setAuthStart,
  setSSOError,
  setSSOToken,
  setSessionId,
  setTenenatId,
} = authSlice.actions

export default authSlice.reducer
