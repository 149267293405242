import { AlertColor } from '@mui/material'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  FetchUsersThunkPayloadType,
  CreateUserThunkPayloadType,
  EditUserThunkDataType,
  CreatedUserResponseType,
} from '../../types/user.types'

import { normalizePhone } from '../../utils/index'

import {
  apiEditPhone,
  apiEditUser,
  apiResetPassword,
  apiCreateUser,
  apiDeleteUser,
  apiGetUserById,
  apiGetUsers,
} from '../../services/api'

import { CatchErrorType } from '../../types/redux'
import { getSSOToken } from '../selectors'
import { RootState } from '../store'

import { alertTypes } from '../../constants/common/index'
import { prepareErrorText } from '../../utils'

import {
  deleteUserById,
  fetchUser,
  firstUsersLoad,
  loadMoreUsers,
  setUserUpdatedPhone,
} from '../slices/usersSlice'
import { addAlert } from '../slices/alertsList'

export const fetchUsers = createAsyncThunk<void, FetchUsersThunkPayloadType>(
  'users/fetch',
  // eslint-disable-next-line consistent-return
  async ({ params, onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetUsers({ params, ssoToken })
      if (params.skipToken) {
        thunkAPI.dispatch(loadMoreUsers(data))
      } else {
        thunkAPI.dispatch(firstUsersLoad(data))
      }
      onSuccess()
      thunkAPI.dispatch(
        addAlert({
          id: 'usersFetchSuccess',
          type: alertTypes.success as AlertColor,
          text: 'Дані користувачів успішно оновилися',
        })
      )
    } catch (e) {
      const error = e as CatchErrorType
      onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchUsersError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Не вдалося завантажити користувачів',
        })
      )

      return thunkAPI.rejectWithValue(
        'Не вдалося завантажити список користувачів'
      )
    }
  }
)

type FetchUserByIdPayloadType = {
  id: string
  onSuccess?: (user: CreatedUserResponseType) => void
  onError?: (error: CatchErrorType) => void
}

export const fetchUserById = createAsyncThunk<void, FetchUserByIdPayloadType>(
  'users/fetchUser',
  // eslint-disable-next-line consistent-return
  async (payload, thunkAPI) => {
    const { id, onSuccess, onError } = payload
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetUserById({ id, ssoToken })
      thunkAPI.dispatch(fetchUser(data))
      if (onSuccess) onSuccess(data)
    } catch (e) {
      const error = e as CatchErrorType
      if (onError) onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchUserError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході відкриття профіля користувача',
        })
      )

      return thunkAPI.rejectWithValue('Не вдалося завантажити дані користувача')
    }
  }
)

type DeleteUserThunkPayload = {
  id: string
  onSuccess?: () => void
  onError?: (error: CatchErrorType) => void
}

export const deleteUser = createAsyncThunk<void, DeleteUserThunkPayload>(
  'users/delete',
  // eslint-disable-next-line consistent-return
  async ({ id, onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      await apiDeleteUser({ id, ssoToken })
      thunkAPI.dispatch(deleteUserById(id))

      if (onSuccess) onSuccess()
    } catch (e) {
      const error = e as CatchErrorType
      if (onError) onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'deleteUserError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході видалення користувача',
        })
      )

      return thunkAPI.rejectWithValue('Не вдалося видалити користувача')
    }
  }
)

export const createUser = createAsyncThunk<
  CreatedUserResponseType,
  CreateUserThunkPayloadType
>('users/create', async ({ body, onSuccess, onError }, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    const data: CreatedUserResponseType = await apiCreateUser({
      ssoToken,
      body,
    })
    onSuccess(data)
    thunkAPI.dispatch(
      addAlert({
        id: 'userCreated',
        type: alertTypes.success as AlertColor,
        text: 'Вітаємо! Нового користувача успішно створено!',
      })
    )
    return data
  } catch (e: unknown) {
    const error = e as CatchErrorType
    onError(error)
    thunkAPI.dispatch(
      addAlert({
        id: 'createUserError',
        type: alertTypes.error as AlertColor,
        text: error?.response?.data?.length
          ? prepareErrorText(error.response.data)
          : 'Виникла помилка в ході створення нового користувача',
      })
    )
    return thunkAPI.rejectWithValue('Не вдалося створити користувача')
  }
})

type ResetPasswordThunkPayloadType = {
  id: string
  onSuccess?: () => void
  onError?: (error: CatchErrorType) => void
}

export const resetPassword = createAsyncThunk<
  void,
  ResetPasswordThunkPayloadType
>(
  'users/create',
  // eslint-disable-next-line consistent-return
  async ({ id, onSuccess, onError }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiResetPassword({ id, ssoToken })
      if (onSuccess) onSuccess()
    } catch (e: unknown) {
      const error = e as CatchErrorType
      if (onError) onError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'passwordResetError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході скидання паролю',
        })
      )
      return thunkAPI.rejectWithValue('Не вдалося скинути пароль')
    }
  }
)

type EditUserDataType = {
  data: EditUserThunkDataType
  onUserDataSuccess?: () => void
  onPhoneSuccess?: () => void
  onUserDataError?: (error: CatchErrorType) => void
  onPhoneError?: (error: CatchErrorType) => void
}

export const editUserData = createAsyncThunk<void, EditUserDataType>(
  'users/editUser',
  // eslint-disable-next-line consistent-return
  async (body, thunkAPI) => {
    const { data, onUserDataSuccess, onUserDataError } = body
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, mobilePhone, ...rest } = data
      await apiEditUser({ body: { id, ...rest }, ssoToken })
      if (onUserDataSuccess) onUserDataSuccess()
      const updatedUserData = await apiGetUserById({ id, ssoToken })
      thunkAPI.dispatch(fetchUser(updatedUserData))
    } catch (e: unknown) {
      const error = e as CatchErrorType
      if (onUserDataError) onUserDataError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'editUserData',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході оновлення даних користувача',
        })
      )
      return thunkAPI.rejectWithValue('Не вдалося оновити дані користувача')
    }
  }
)

export const editUserPhone = createAsyncThunk<void, EditUserDataType>(
  'users/editUserPhone',
  // eslint-disable-next-line consistent-return
  async (body, thunkAPI) => {
    const { data, onPhoneSuccess, onPhoneError } = body
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const { id, mobilePhone } = data
      await apiEditPhone({
        body: { id, mobilePhone: normalizePhone(mobilePhone) },
        ssoToken,
      })
      if (onPhoneSuccess) onPhoneSuccess()
      thunkAPI.dispatch(setUserUpdatedPhone(normalizePhone(mobilePhone)))
    } catch (e: unknown) {
      const error = e as CatchErrorType
      if (onPhoneError) onPhoneError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'editUserData',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході оновлення номера телефону користувача',
        })
      )
      return thunkAPI.rejectWithValue(
        'Не вдалося оновити номер телефону користувача'
      )
    }
  }
)
