import colors from '../colors'

export default {
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        '&.MuiRadio-colorPrimary, &.MuiRadio-colorPrimary + .MuiTypography-root':
          {
            color: colors.primary.main,
          },
        '&.MuiRadio-colorSecondary, &.MuiRadio-colorSecondary + .MuiTypography-root':
          {
            color: colors.secondary.main,
          },
        '&.MuiRadio-colorSuccess, &.MuiRadio-colorSuccess + .MuiTypography-root':
          {
            color: colors.success.main,
          },
        '&.MuiRadio-colorError, &.MuiRadio-colorError + .MuiTypography-root': {
          color: colors.error.main,
        },
        '& + .MuiTypography-root': {
          fontWeight: 700,
          marginLeft: 7,
          marginBottom: 2,
        },
        '&.Mui-disabled.Mui-checked, &.Mui-disabled + .MuiTypography-root': {
          color: colors.grey['800'],
        },
        '&.Mui-disabled': {
          color: colors.grey['600'],
        },
      },
    },
  },
}
