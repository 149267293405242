import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IpText } from '../../components/ui/IpText'
import { IpButton } from '../../components/ui/IpButton'

import { routesConfiguration } from '../../routing'
import { usePermissions } from '../../hooks/usePermissions'
import { useAppDispatch, useAppSelector } from '../../redux/store'

import { LoaderPortal } from '../../components/Loader/LoaderPortal'
import { FilterForm } from '../../components/FilterForm'
import { CustomTable } from '../../components/CustomTable'

import { CommunityListTableData } from '../../types/table.types'
import { FilterFields } from '../../types/filter.types'
import { defaultPageSize } from '../../constants/forms/users'
import { filterCommunitiesList } from '../../constants/forms/community'
import { permissionResourcesKeys } from '../../constants/common'

import {
  fetchCommunities,
  fetchCommunityById,
} from '../../redux/thunk/communitiesThunk'
import { updateCommunitiesFilter } from '../../redux/slices/communitySlice'
import { getCommunitiesFilter } from '../../redux/selectors'
import { useTracking } from '../../hooks/tracking/useTracking'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'
import './index.scss'

const {
  communitiesCreate,
  communitiesItadminEdit,
  communitiesView,
  communitiesEdit,
} = permissionResourcesKeys

const {
  errorCommunitiesListLoad,
  successCommunitiesListLoad,
  errorLoadCommunity,
  successLoadCommunity,
} = trackEvents.communities

export const CommunityList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getIsOperationAllowed } = usePermissions()
  const { trackEvent } = useTracking()

  const { pageSize } = useAppSelector(getCommunitiesFilter)

  const [isNextBtnDisable, setNextBtnDisable] = useState(false)

  const orderByFields = ['uniqueCommunityId']

  const {
    data: { currentPage, skipToken },
    filter,
    isLoading,
  } = useAppSelector((state) => state.communities)

  const transformedCommunities = useMemo(
    () =>
      currentPage.map((item) => ({
        id: item.id,
        communityName: item.name,
        uniqueCommunityId: item.uniqueCommunityId,
        parentStructure: item.regionId,
      })),
    [currentPage]
  )

  useMemo(() => {
    if (skipToken?.length) {
      setNextBtnDisable(false)
    } else {
      setNextBtnDisable(true)
    }
  }, [skipToken])

  const openCommunityProfile = (row: CommunityListTableData) => {
    const { id } = row
    const onSuccess = () => {
      trackEvent({ name: successLoadCommunity, payload: row })
      navigate(routesConfiguration.communityProfile.path)
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorLoadCommunity, payload: error })
    }
    dispatch(fetchCommunityById({ id, onSuccess, onError }))
  }

  const onNextPageClick = () => {
    const payload = {
      params: { ...filter, skipToken: skipToken || '' },
      onSuccess: onSuccessLoadList,
      onError: (error: CatchErrorType) => onErrorLoadList(error),
    }
    dispatch(fetchCommunities(payload))
  }

  const submitHandler = (newFilters: FilterFields) => {
    dispatch(updateCommunitiesFilter({ ...filter, ...newFilters }))
  }

  const onSortAndFilterHandle = (newFilters: FilterFields) => {
    dispatch(updateCommunitiesFilter({ ...filter, ...newFilters }))
  }

  const onRowsPerPageChanged = (rowsCount: string) => {
    dispatch(updateCommunitiesFilter({ ...filter, pageSize: rowsCount }))
  }

  useEffect(() => {
    const payload = {
      params: { pageSize: defaultPageSize, ...filter },
      onSuccess: onSuccessLoadList,
      onError: (error: CatchErrorType) => onErrorLoadList(error),
    }
    dispatch(fetchCommunities(payload))
  }, [filter])

  function onSuccessLoadList() {
    trackEvent({
      name: successCommunitiesListLoad,
      payload: { pageSize: defaultPageSize, ...filter },
    })
  }

  function onErrorLoadList(error: CatchErrorType) {
    trackEvent({ name: errorCommunitiesListLoad, payload: error })
  }

  return (
    <>
      <LoaderPortal show={isLoading} />
      <IpText variant="h4">Громади</IpText>

      <FilterForm
        submitHandler={submitHandler}
        list={filterCommunitiesList}
        defaultOrderBy="uniqueCommunityId"
        orderDir={filter.orderDir || 'asc'}
      />

      <CustomTable
        className="edit-list-form"
        data={transformedCommunities}
        type="communityList"
        isPossibleSelectRow={true}
        isPossibleSelectAllRows={true}
        onNextPageClick={onNextPageClick}
        isNextBtnDisable={isNextBtnDisable}
        onSortAndFilter={onSortAndFilterHandle}
        orderByFields={orderByFields}
        onRowClick={openCommunityProfile}
        onRowsPerPageChanged={onRowsPerPageChanged}
        defaultRowsPerPage={pageSize ? +pageSize : 5}
        defaultOrderBy="userPrincipalName"
        defaultOrderDir="asc"
        allowDelete={getIsOperationAllowed([])}
        allowView={getIsOperationAllowed([communitiesView])}
        allowEdit={getIsOperationAllowed([communitiesEdit])}
      />

      {getIsOperationAllowed([communitiesCreate, communitiesItadminEdit]) && (
        <IpButton
          classes="create-community-btn"
          variant="contained"
          size="large"
          onClick={() => navigate(routesConfiguration.createCommunity.path)}
        >
          Створити громаду
        </IpButton>
      )}
    </>
  )
}
