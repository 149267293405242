import React, { useEffect } from 'react'
import { Alert, AlertColor } from '@mui/material'
import { defaultAlertTimeout } from '../../constants/common'
import { deleteAlert } from '../../redux/slices/alertsList'
import { useAppDispatch } from '../../redux/store'

import './index.scss'

type PropsType = {
  type: AlertColor
  text: string
  id: string
  alertTimeout?: number
  index: number
}

export const CustomAlert: React.FC<PropsType> = ({
  id,
  type,
  text,
  alertTimeout = defaultAlertTimeout,
  index,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null
    if (alertTimeout) {
      timeout = setTimeout(() => {
        dispatch(deleteAlert(id))
      }, alertTimeout)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [alertTimeout, deleteAlert, id])

  return (
    <Alert
      className="custom-alert"
      severity={type}
      style={{
        position: 'fixed',
        transform: `translateY(-${index * 60}px)`,
      }}
    >
      {text}
    </Alert>
  )
}
