import { CSSProperties } from 'react'
import { createTheme } from '@mui/material/styles'
import { defaultTheme } from './theme/index'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: CSSProperties
    body3: CSSProperties
    body4: CSSProperties
  }
  interface TypographyVariantsOptions {
    subtitle3?: CSSProperties
    body3?: CSSProperties
    body4?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true
    body3: true
    body4: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraSmall: true
  }
}

// @ts-ignore
export default createTheme({
  ...defaultTheme,
})
