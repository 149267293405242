import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export function LoaderIndicator() {
  return (
    <Backdrop open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
