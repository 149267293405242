import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCommunityName,
  maxLengthCommunityName,
  requiredCommunityName,
  communityNameRegexp,
  notMatchCommunityName,
} = validation

export const getCommunityNameSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCommunityName, maxLengthCommunityName)
    .matches(communityNameRegexp, notMatchCommunityName)

  schema = isRequired
    ? schema.required(requiredCommunityName)
    : schema.notRequired()

  return schema
}
