import React from 'react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { AuthProvider } from './components/AuthProvider'
import { Init } from './components/Init'
import { Routing } from './routing'
import { reactPlugin } from './AppInsights'

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AuthProvider>
        <Init>
          <Routing />
        </Init>
      </AuthProvider>
    </AppInsightsContext.Provider>
  )
}

export default App
