import { createAsyncThunk } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material'
import { getAuthSSOToken } from '../../auth'
import { alertTypes } from '../../constants/common'
import { addAlert } from '../slices/alertsList'
import { setAuthStart, setSSOToken, setSSOError } from '../slices/auth'

const showFailSSOAuthToster = () => {
  return addAlert({
    id: 'authError',
    type: alertTypes.error as AlertColor,
    text: 'Помилка в ході автентифікації',
  })
}

type AuthThunkPayloadType = {
  isRefresh: boolean
  onSuccess?: () => void
  onError?: (error: string) => void
}

export const authThunk = createAsyncThunk<void, AuthThunkPayloadType>(
  'auth/getToken',
  async ({ isRefresh, onSuccess, onError }, thunkAPI) => {
    if (!isRefresh) thunkAPI.dispatch(setAuthStart(true))
    const errorTosterAction = showFailSSOAuthToster()
    try {
      const ssoToken = await getAuthSSOToken()
      thunkAPI.dispatch(setSSOToken(ssoToken))
      if (onSuccess) onSuccess()
    } catch (error: unknown) {
      const catchError = error as string
      if (onError) onError(catchError)
      thunkAPI.dispatch(setSSOError(catchError))
      thunkAPI.dispatch(errorTosterAction)
    }
  }
)
