import React, { Fragment, useEffect } from 'react'
import { FormikProps, useFormik } from 'formik'

import { IpButton } from '../ui/IpButton'

import { TextFormField } from '../formFields/TextFormField'

import { formFieldsTypes, formFieldTypes } from '../../types/formFields.types'
import { useValidation } from '../../hooks/validation/useValidation'
import { useFormData } from '../../hooks/useFormData'
import { SearchAndSelectFormField } from '../formFields/SearchAndSelectFormField'
import { useSearchCommunityField } from '../../hooks/useSearchCommunityField'
import { concatCommunityNameAndId } from '../../utils'
import { useAppSelector } from '../../redux/store'
import { getCommunities } from '../../redux/selectors'

export type formikValuesTypes = {
  search: string
  orderBy: string
  filterBy: string
  filterValue: string
  community?: string
  orderDir: 'desc' | 'asc'
  [key: string]: string | undefined
}

type PropsTypes = {
  submitHandler: (values: formikValuesTypes) => void
  list: formFieldsTypes
  defaultOrderBy: string
  isWithCommunity?: boolean
  orderDir: 'desc' | 'asc'
}

export const FilterForm: React.FC<PropsTypes> = ({
  submitHandler,
  list,
  defaultOrderBy,
  isWithCommunity,
  orderDir = 'asc',
}) => {
  const {
    searchCommunitiesResult: {
      communities,
      storedSearchValue,
      prevStoredSearchValue,
    },
  } = useAppSelector(getCommunities)
  const initialValues: formikValuesTypes = {
    search: '',
    filterBy: '',
    filterValue: '',
    orderBy: defaultOrderBy,
    orderDir,
    ...(isWithCommunity && { community: prevStoredSearchValue || '' }),
  }
  const { formSchema } = useValidation([])
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setErrors,
    setFieldTouched,
  }: FormikProps<formikValuesTypes> = useFormik<formikValuesTypes>({
    initialValues,
    validationSchema: formSchema,
    onSubmit: () => {
      const communityId = communities.find(
        (item) =>
          concatCommunityNameAndId(item.name, item.uniqueCommunityId) ===
          values.community
      )?.uniqueCommunityId
      const dataToSubmit = {
        ...values,
        communityId,
      }
      submitHandler(dataToSubmit)
    },
  })
  const {
    onSearch,
    onCommunityChange,
    communitiesOptions,
    areCommunitiesLoading,
    isCommunityValueValid,
  } = useSearchCommunityField({
    isTouched: touched.community,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    setErrors,
    communityValue: values.community,
  })
  const { checkIsDataChanged, checkIsFormValid, isFormValid } = useFormData()

  useEffect(() => {
    if (isWithCommunity && !values.community && storedSearchValue) {
      setFieldValue('community', storedSearchValue)
    }
  }, [storedSearchValue, values, isWithCommunity])

  useEffect(() => {
    setFieldValue('orderDir', orderDir)
  }, [orderDir])

  useEffect(() => {
    checkIsDataChanged(values, initialValues)
  }, [values, checkIsDataChanged])

  useEffect(() => {
    checkIsFormValid(errors)
  }, [errors, checkIsFormValid])

  return (
    <form onSubmit={handleSubmit}>
      {Object.values(list).map((fieldProps: formFieldTypes) => {
        return (
          <Fragment key={fieldProps.name}>
            {fieldProps.type === 'text' && fieldProps.name !== 'community' && (
              <TextFormField
                {...fieldProps}
                touched={touched[fieldProps.name]}
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={errors[fieldProps.name]}
              />
            )}
            {isWithCommunity && fieldProps.name === 'community' && (
              <SearchAndSelectFormField
                {...fieldProps}
                options={communitiesOptions}
                onBlurSearch={handleBlur}
                onSearch={onSearch}
                error={errors[fieldProps.name]}
                touched={touched[fieldProps.name]}
                value={values[fieldProps.name]}
                freeSolo={!!values[fieldProps.name]?.length}
                isLoading={areCommunitiesLoading}
                noOptionsText="Почніть вводити перші літери назви громади, або цифри ідентифікатора громади"
                loadingText="Триває пошук громад..."
                onChange={onCommunityChange}
              />
            )}
          </Fragment>
        )
      })}
      <IpButton
        variant="contained"
        size="large"
        type="submit"
        disabled={!isFormValid || (isWithCommunity && isCommunityValueValid)}
      >
        Пошук
      </IpButton>
    </form>
  )
}
