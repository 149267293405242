import { formFieldsTypes } from '../../types/formFields.types'

export const createSchoolFields: formFieldsTypes = {
  schoolName: {
    type: 'text',
    name: 'schoolName',
    label: 'Назва установи',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  schoolId: {
    type: 'text',
    name: 'schoolId',
    label: 'Ідентифікатор установи',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  community: {
    type: 'text',
    name: 'community',
    label: 'Громада',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
}

export const editSchoolFields: formFieldsTypes = {
  schoolName: {
    type: 'text',
    name: 'schoolName',
    label: 'Назва установи',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  schoolId: {
    type: 'text',
    name: 'schoolId',
    label: 'Ідентифікатор установи',
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  community: {
    type: 'text',
    name: 'community',
    label: 'Установа, якій підпорядковується',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  sharedMailbox: {
    type: 'text',
    name: 'sharedMailbox',
    label: 'Email адреса',
    variant: 'outlined',
    required: false,
    disabled: true,
  },
}

export const filterSchoolsList: formFieldsTypes = {
  search: {
    type: 'text',
    name: 'search',
    label: 'Пошук по ключовим словам',
    variant: 'outlined',
    required: false,
  },
  community: {
    type: 'text',
    name: 'community',
    label: 'Громада',
    variant: 'outlined',
    required: false,
    disabled: false,
  },
}
