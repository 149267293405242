import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitStateType = {
  rolesToCreate: Array<string>
}

const initialState: InitStateType = {
  rolesToCreate: [],
}

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setRolesToCreate: (state, { payload }: PayloadAction<string[]>) => {
      state.rolesToCreate = payload
    },
  },
})
export const { setRolesToCreate } = initSlice.actions

export const { reducer: init } = initSlice
