import MuiButton from './MuiButton'
import MuiDivider from './MuiDivider'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiCheckbox from './MuiCheckbox'
import MuiRadio from './MuiRadio'
import MuiBadge from './MuiBadge'

export default {
  ...MuiBadge,
  ...MuiButton,
  ...MuiDivider,
  ...MuiCheckbox,
  ...MuiFormControlLabel,
  ...MuiRadio,
}
