import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { infoPageSliceType } from '../../types/infoPage.types'
import { getInfoPage } from '../thunk/infoPageThunk'

const initialState: infoPageSliceType = {
  data: {
    adminUnitName: '',
    adminUnitDescription: '',
    level: '',
  },
  isLoading: false,
  error: '',
}

export const infoPageSlice = createSlice({
  name: 'infoPage',
  initialState,
  reducers: {
    setInformation: (state, { payload }) => {
      state.data = payload
      state.isLoading = false
    },
  },
  extraReducers: {
    [getInfoPage.pending.type]: (state) => {
      state.isLoading = true
    },
    [getInfoPage.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [getInfoPage.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const { setInformation } = infoPageSlice.actions

export default infoPageSlice.reducer
