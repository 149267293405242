import {
  formFieldsTypes,
  selectOptionTypes,
} from '../../types/formFields.types'

export const activeMFA = 'Активовано'
export const inactiveMFA = 'Не активовано'

export const studentKey = 'Student'
export const teacherKey = 'Teacher'
export const itAdminKey = 'ItAdmin'
export const officeStaffKey = 'OfficeStaff'
export const principalKey = 'Principal'

export const userRoles: Array<selectOptionTypes> = [
  {
    value: studentKey,
    label: 'Учень',
  },
  {
    value: teacherKey,
    label: 'Вчитель',
  },
  {
    value: itAdminKey,
    label: 'ІТ-адміністратор',
  },
  {
    value: officeStaffKey,
    label: 'Адміністративний персонал',
  },
  {
    value: principalKey,
    label: 'Директор',
  },
]

export const createUserFields: formFieldsTypes = {
  lastName: {
    type: 'text',
    name: 'lastName',
    label: 'Прізвище',
    variant: 'outlined',
    required: true,
  },
  firstName: {
    type: 'text',
    name: 'firstName',
    label: "Ім'я",
    variant: 'outlined',
    required: true,
  },
  userIdNumber: {
    type: 'text',
    name: 'userIdNumber',
    label:
      'Ідентифікуючий номер (серія, номер ідентифікуючого документу або Унікальний ідентифікатор з єдиної шкільної системи, тощо)',
    variant: 'outlined',
    required: true,
  },
  userRole: {
    type: 'select',
    name: 'userRole',
    label: 'Роль користувача',
    variant: 'outlined',
    required: true,
    options: userRoles,
  },
  userJobTitle: {
    type: 'text',
    name: 'userJobTitle',
    label: 'Посада',
    variant: 'outlined',
    required: false,
  },
  userPhoneNumber: {
    type: 'text',
    name: 'userPhoneNumber',
    label: 'Телефон',
    variant: 'outlined',
    required: false,
  },
  mfaStatus: {
    type: 'checkbox',
    name: 'mfaStatus',
    label: 'Статус активації багатофакторної автентифікації',
    variant: 'outlined',
    required: false,
  },
}

export const editUserFields: formFieldsTypes = {
  lastName: {
    type: 'text',
    name: 'lastName',
    label: 'Прізвище',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  firstName: {
    type: 'text',
    name: 'firstName',
    label: "Ім'я",
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  displayName: {
    type: 'text',
    name: 'displayName',
    label: "Відображуване ім'я",
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  companyName: {
    type: 'text',
    name: 'companyName',
    label: 'Назва установи',
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  userIdNumber: {
    type: 'text',
    name: 'userIdNumber',
    label:
      'Ідентифікуючий номер (серія, номер ідентифікуючого документу або Унікальний ідентифікатор з єдиної шкільної системи, тощо)',
    variant: 'outlined',
    required: true,
    disabled: false,
  },
  userRole: {
    type: 'select',
    name: 'userRole',
    label: 'Роль користувача',
    variant: 'outlined',
    required: true,
    disabled: false,
    options: userRoles,
  },
  userJobTitle: {
    type: 'text',
    name: 'userJobTitle',
    label: 'Посада',
    variant: 'outlined',
    required: false,
    disabled: false,
  },
  employeeId: {
    type: 'text',
    name: 'employeeId',
    label: 'Унікальний ID користувача',
    variant: 'outlined',
    required: false,
    disabled: true,
  },
  userPrincipalName: {
    type: 'text',
    name: 'userPrincipalName',
    label: 'User name користувача',
    variant: 'outlined',
    required: true,
    disabled: true,
  },
  userPhoneNumber: {
    type: 'text',
    name: 'userPhoneNumber',
    label: 'Телефон',
    variant: 'outlined',
    required: false,
    disabled: false,
  },
  mfaStatus: {
    type: 'checkbox',
    name: 'mfaStatus',
    label: 'Cтатус активації багатофакторної автентифікації',
    variant: 'outlined',
    required: false,
    disabled: false,
  },
}

export const filterUsersList: formFieldsTypes = {
  search: {
    type: 'text',
    name: 'search',
    label: 'Пошук по ключовим словам',
    variant: 'outlined',
    required: false,
  },
}

export const defaultPageSize = '5'

export const fieldsToAllowEditingByRoles = {
  default: [
    editUserFields.userIdNumber.name,
    editUserFields.firstName.name,
    editUserFields.lastName.name,
    editUserFields.userPhoneNumber.name,
    editUserFields.userJobTitle.name,
    editUserFields.userRole.name,
    editUserFields.mfaStatus.name,
  ],
  itAdmin: [
    editUserFields.firstName.name,
    editUserFields.lastName.name,
    editUserFields.userPhoneNumber.name,
    editUserFields.userJobTitle.name,
  ],
}
