import { SESSION_ID_STORAGE_KEY } from '../constants/common/index'
import {
  EditCommunityParamType,
  EditSchoolDataParamType,
  EditSchoolParentParamType,
  EditUserPhoneParamType,
  EditUserParamType,
  GetSchoolsParamType,
  GetSchoolByIdParamType,
  CreateSchoolParamType,
  ResetPasswordParamType,
  CreateUserParamType,
  GetUserByIdParamType,
  GetUsersParamType,
  GetRolesOptionsParamType,
  GetInfoPageDataParamType,
  GetCommunitiesParamType,
  GetCommunityByIdParamType,
  PostCommunityParamType,
  DeleteUserParamType,
} from '../types/services.types'

import { api } from '../axiosInstance'
import { concatQueryParams } from '../utils'

type GetHeadersParamType = {
  ssoToken: string
}

const getHeaders = ({ ssoToken }: GetHeadersParamType) => {
  return {
    headers: {
      Authorization: `Bearer ${ssoToken}`,
      'AI-SessionId': sessionStorage.getItem(SESSION_ID_STORAGE_KEY) || '',
    },
  }
}

export const apiGetCommunityById = async ({
  id,
  ssoToken,
}: GetCommunityByIdParamType) => {
  const { data } = await api.get(`communities/${id}`, getHeaders({ ssoToken }))
  return data
}

export const apiGetCommunities = async ({
  params,
  ssoToken,
}: GetCommunitiesParamType) => {
  const { data } = await api.get(
    `communities${concatQueryParams(params)}`,
    getHeaders({ ssoToken })
  )
  return data
}

export const apiCreteCommunity = async ({
  body,
  ssoToken,
}: PostCommunityParamType) => {
  await api.post('communities', body, getHeaders({ ssoToken }))
}

export const apiEditCommunity = async ({
  body: { id, name },
  ssoToken,
}: EditCommunityParamType) => {
  await api.put(`communities/${id}`, { name }, getHeaders({ ssoToken }))
}

export const apiGetInfoPageData = async ({
  ssoToken,
}: GetInfoPageDataParamType) => {
  const { data } = await api.get('infopage', getHeaders({ ssoToken }))
  return data
}

export const apiGetRolesOptions = async ({
  ssoToken,
}: GetRolesOptionsParamType) => {
  const { data } = await api.get(
    'users/roles/options',
    getHeaders({ ssoToken })
  )
  return data
}

export const apiGetUsers = async ({ ssoToken, params }: GetUsersParamType) => {
  const { data } = await api.get(
    `users${concatQueryParams(params)}`,
    getHeaders({ ssoToken })
  )
  return data
}

export const apiGetUserById = async ({
  ssoToken,
  id,
}: GetUserByIdParamType) => {
  const { data } = await api.get(`users/${id}`, getHeaders({ ssoToken }))
  return data
}

export const apiDeleteUser = async ({ ssoToken, id }: DeleteUserParamType) => {
  const { data } = await api.delete(`users/${id}`, getHeaders({ ssoToken }))
  return data
}

export const apiCreateUser = async ({
  ssoToken,
  body,
}: CreateUserParamType) => {
  const { data } = await api.post('users', body, getHeaders({ ssoToken }))
  return data
}

export const apiResetPassword = async ({
  ssoToken,
  id,
}: ResetPasswordParamType) => {
  await api.post(`users/${id}/password/reset`, {}, getHeaders({ ssoToken }))
}

export const apiCreateSchool = async ({
  ssoToken,
  body,
}: CreateSchoolParamType) => {
  await api.post('schools', body, getHeaders({ ssoToken }))
}

export const apiEditSchool = async ({
  ssoToken,
  body,
}: EditSchoolDataParamType) => {
  const { schoolId, name } = body
  await api.put(`schools/${schoolId}`, { name }, getHeaders({ ssoToken }))
}

export const apiEditSchoolParent = async ({
  ssoToken,
  body,
}: EditSchoolParentParamType) => {
  const { schoolId, communityId } = body
  await api.put(
    `schools/${schoolId}/community`,
    { communityId },
    getHeaders({ ssoToken })
  )
}

export const apiGetSchoolById = async ({
  ssoToken,
  id,
}: GetSchoolByIdParamType) => {
  const { data } = await api.get(`schools/${id}`, getHeaders({ ssoToken }))
  return data
}

export const apiGetSchools = async ({
  ssoToken,
  params,
}: GetSchoolsParamType) => {
  const { data } = await api.get(
    `schools${concatQueryParams(params)}`,
    getHeaders({ ssoToken })
  )
  return data
}

export const apiEditUser = async ({ ssoToken, body }: EditUserParamType) => {
  const { id, ...rest } = body
  const { data } = await api.put(`users/${id}`, rest, getHeaders({ ssoToken }))
  return data
}

export const apiEditPhone = async ({
  ssoToken,
  body,
}: EditUserPhoneParamType) => {
  const { id, ...rest } = body
  const { data } = await api.put(
    `users/${id}/phone`,
    rest,
    getHeaders({ ssoToken })
  )
  return data
}
