import { FormikErrors } from 'formik'
import { useState } from 'react'

const ignoreFields: string[] = []

interface ValuesType<T> {
  [key: string]: T
}
export function useFormData() {
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)

  function checkIsDataChanged<T>(
    values: ValuesType<T>,
    initialValues: ValuesType<T>
  ) {
    const changedFields = Object.keys(values).filter(
      (field) =>
        !ignoreFields.includes(field) && values[field] !== initialValues[field]
    )
    setIsDataChanged(!!changedFields.length)
  }

  function checkIsFormValid<T>(errors: FormikErrors<ValuesType<T>>) {
    setIsFormValid(!Object.keys(errors).length)
  }

  function getInitialErrorFields<T>(
    values: ValuesType<T>,
    errors: FormikErrors<ValuesType<T>>
  ) {
    const isFullDataSettled = Object.values(values).every(Boolean)
    const errorFields = Object.keys(errors)
    return isFullDataSettled && !!errorFields.length ? errorFields : []
  }

  return {
    isDataChanged,
    checkIsDataChanged,
    isFormValid,
    checkIsFormValid,
    getInitialErrorFields,
  }
}
