import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { v4 as uuidv4 } from 'uuid'
import { SESSION_ID_STORAGE_KEY } from './constants/common/index'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    disableFetchTracking: true,
    extensions: [reactPlugin],
  },
})

appInsights.loadAppInsights()
appInsights.context.session.id = uuidv4()
const sessionId = appInsights.context.getSessionId()
sessionStorage.setItem(SESSION_ID_STORAGE_KEY, sessionId)

export { reactPlugin, appInsights }
