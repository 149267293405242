// @ts-ignore
import colors from './colors.scss'

export default {
  primary: {
    main: colors.primary_main,
    dark: colors.primary_dark,
  },
  secondary: {
    light: colors.secondary_light,
    main: colors.secondary_main,
    dark: colors.secondary_dark,
  },
  grey: {
    600: colors.grey_600,
    700: colors.grey_700,
    800: colors.grey_800,
  },
  error: {
    main: colors.error,
  },
  success: {
    main: colors.success,
  },
  warning: {
    main: colors.warning,
  },
  background: {
    default: colors.background,
  },
  text: {
    primary: colors.text_primary,
    secondary: colors.grey_800,
  },
}
