import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsJobTitle,
  maxLengthJobTitle,
  jobTitleRegexp,
  notMatchJobTitle,
} = validation

export const getJobTitleSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsJobTitle, maxLengthJobTitle)
    .matches(jobTitleRegexp, notMatchJobTitle)

  schema = isRequired ? schema.required('') : schema.notRequired()

  return schema
}
