import React, { ChangeEvent, FocusEvent } from 'react'
import { styled } from '@mui/material/styles'
import { Checkbox, Typography, FormControlLabel } from '@mui/material'
import { IpCheckboxProps } from '../../../types/ui/IpCheckbox.types'
import {
  INPUT_SIZE,
  DIFF_BETWEEN_INPUT_AND_TEXT,
} from '../../../constants/ui.constants'

type IpCheckboxPropsReact = IpCheckboxProps & {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

const IpCheckboxStyled = styled('div')(({ size }: IpCheckboxPropsReact) => ({
  '& .MuiSvgIcon-root': { fontSize: size },
}))

export function IpCheckbox(props: IpCheckboxPropsReact) {
  const {
    label,
    size = INPUT_SIZE,
    value,
    color,
    disabled,
    required,
    checked,
    classes,
    name,
    onChange,
  } = props

  return (
    <IpCheckboxStyled size={size}>
      <FormControlLabel
        control={
          <Checkbox
            color={color}
            value={value}
            disabled={disabled}
            required={required}
            checked={checked}
            classes={{ root: classes }}
            onChange={onChange}
            name={name}
          />
        }
        label={
          <Typography
            fontSize={size - DIFF_BETWEEN_INPUT_AND_TEXT}
            lineHeight={1}
          >
            {label}
          </Typography>
        }
      />
    </IpCheckboxStyled>
  )
}
