import { string } from 'yup'
import { validation } from '../../../constants/forms/validation'

const {
  maxCharsFirstName,
  maxLengthFirstName,
  requiredFirstName,
  invalidSymbolFirstName,
  nameValidation,
} = validation

export const getFirstNameSchema = (isRequired: boolean) => {
  let schema = string()
    .max(maxCharsFirstName, maxLengthFirstName)
    .matches(nameValidation, invalidSymbolFirstName)

  schema = isRequired
    ? schema.required(requiredFirstName)
    : schema.notRequired()

  return schema
}
