import { RootState } from '../store'

export const getAlerts = ({ alertsList }: RootState) => alertsList.alertsList
export const getSSOToken = ({ auth }: RootState) => auth.ssoToken
export const getAuthState = ({ auth }: RootState) => auth
export const getTenantId = ({ auth }: RootState) => auth.tenantId
export const getPermissions = ({ permissions }: RootState) =>
  permissions.permissionsList
export const getRolesToCreate = ({ init }: RootState) => init.rolesToCreate
export const infoPageSelector = ({ infopage }: RootState) => infopage
export const getCommunities = ({ communities }: RootState) => communities
export const getCommunitiesFilter = ({ communities }: RootState) =>
  communities.filter
export const getCurrentSchool = ({ schools }: RootState) =>
  schools.currentSchool
export const getSchoolFilter = ({ schools }: RootState) => schools.filter
export const getIsSchoolLoading = ({ schools }: RootState) => schools.isLoading
export const getCurrentUser = ({ users }: RootState) => users.currentUser
export const getUserFilter = ({ users }: RootState) => users.filter
export const getCurrentCommunity = ({ communities }: RootState) =>
  communities.currentCommunity
