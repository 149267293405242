import React, { useState, useEffect } from 'react'
import { searchCommunities } from '../redux/thunk/communitiesThunk'
import { CreatedCommunityResponseType } from '../types/communities.types'
import { concatCommunityNameAndId } from '../utils/index'
import { getCommunities } from '../redux/selectors/index'
import { useAppDispatch, useAppSelector } from '../redux/store'
import { AutocompleteOptionType } from '../types/filter.types'
import { saveSearchValue } from '../redux/slices/communitySlice'

type PropsType = {
  isTouched?: boolean
  errors: { [key: string]: string | undefined }
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  communityValue?: string
  setFieldValue: (name: string, value: string) => void
  setFieldTouched: (name: string, value: boolean) => void
  setErrors: (errors: { [key: string]: string | undefined }) => void
}

const regexp = /^\(\d*\)\s/

export const useSearchCommunityField = ({
  errors,
  handleChange,
  communityValue,
  setFieldValue,
  setErrors,
  isTouched,
  setFieldTouched,
}: PropsType) => {
  const dispatch = useAppDispatch()

  const [communitiesOptions, setCommunitiesOptions] = useState<
    AutocompleteOptionType[]
  >([])

  const [isCommunityValueValid, setIsCommunityValueValid] =
    useState<boolean>(false)

  const {
    searchCommunitiesResult: { communities },
    isLoading: areCommunitiesLoading,
  } = useAppSelector(getCommunities)

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: searchValue } = event.target
    if (searchValue.length % 2 === 0 && searchValue) {
      dispatch(searchCommunities({ search: searchValue.replace(regexp, '') }))
    }
    if (!searchValue) {
      dispatch(saveSearchValue(''))
    }
    handleChange(event)
  }

  const onCommunityChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | AutocompleteOptionType | null
  ) => {
    const typedValue = value as AutocompleteOptionType | null
    if (typedValue) {
      setFieldValue('community', typedValue.label)
      dispatch(saveSearchValue(typedValue.label))
    } else {
      setFieldValue('community', '')
    }
  }

  useEffect(() => {
    if (!communityValue) {
      setCommunitiesOptions([])
      setFieldTouched('community', false)
    }
  }, [communityValue])

  useEffect(() => {
    setIsCommunityValueValid(!!errors.community)
  }, [errors])

  useEffect(() => {
    if (communities.length && !areCommunitiesLoading) {
      const options = communities.map(
        (community: CreatedCommunityResponseType) => ({
          id: community.uniqueCommunityId,
          label: concatCommunityNameAndId(
            community.name,
            community.uniqueCommunityId
          ),
        })
      )
      setCommunitiesOptions(options)
    }
  }, [communities, areCommunitiesLoading])

  useEffect(() => {
    if (
      communityValue &&
      isTouched &&
      communityValue.length >= 2 &&
      !communitiesOptions.length &&
      !areCommunitiesLoading
    ) {
      setErrors({
        ...errors,
        community: `Громад, які містять "${communityValue}" на початку назви громади, або ідентифікаторі громади не знайдено`,
      })
    }
    if (communitiesOptions.length && communityValue) {
      const community = communitiesOptions.find(
        (item: AutocompleteOptionType) => {
          return (
            item.label.toLowerCase().indexOf(communityValue.toLowerCase()) ===
              0 && item.label.length === communityValue.length
          )
        }
      )
      if (!community) {
        setErrors({ ...errors, community: 'Помилкова назва громади' })
      }
    }
  }, [
    communityValue,
    errors,
    areCommunitiesLoading,
    communitiesOptions,
    isTouched,
  ])

  return {
    onSearch,
    onCommunityChange,
    communitiesOptions,
    communities,
    areCommunitiesLoading,
    isCommunityValueValid,
  }
}
