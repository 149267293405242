/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LoaderState {
  requests: {
    [key: string]: string
  }
}

const initialState: LoaderState = {
  requests: {},
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    addLoader: (state, { payload }: PayloadAction<string>) => {
      state.requests[payload] = payload
    },
    deleteLoder: (state, { payload }: PayloadAction<string>) => {
      const newState = state.requests
      delete newState[payload]
      state.requests = newState
    },
  },
})

export const { addLoader, deleteLoder } = loaderSlice.actions

export const { reducer: loader } = loaderSlice
