export const trackEvents = {
  users: {
    successCreateUser: 'Success create user',
    errorCreateUser: 'Error create user',

    successLoadUsersList: 'Success load users list',
    errorLoadUsersList: 'Error load users list',

    successDeleteUser: 'Success delete user',
    errorDeleteUser: 'Error delete user',

    successReseteUserPassword: 'Success reset user password',
    errorReseteUserPassword: 'Error reset user password',

    successLoadUserProfile: 'Success load user profile',
    errorLoadUserProfile: 'Error load user profile',

    successEditUserProfile: 'Success edit user profile',
    errorEditUserProfile: 'Error edit user profile',

    successEditPhoneNumber: 'Success edit phone number',
    errorEditPhoneNumber: 'Error edit phone number',
  },
  schools: {
    successCreateSchool: 'Success create school',
    errorСreateSchool: 'Error create school',

    successLoadSchoolsList: 'Success load schools list',
    errorLoadschoolsList: 'Error load schools list',

    successLoadSchoolProfile: 'Success load schools profile',
    errorLoadSchoolProfile: 'Error load schools profile',

    successEditSchoolProfile: 'Success edit school profile',
    errorEditSchoolProfile: 'Error edit school profile',

    successEditSchoolParent: 'Success edit school parent',
    errorEditSchoolParent: 'School parent edit error',
  },
  communities: {
    successCreateCommunity: 'Success create community',
    errorCreateCommunity: 'Success create community',

    successCommunitiesListLoad: 'Success communities list load',
    errorCommunitiesListLoad: 'Error communities list load',

    successLoadCommunity: 'Success load community',
    errorLoadCommunity: 'Error load community',

    successEditCommunity: 'Success edit community',
    errorEditCommunity: 'Error edit community',
  },
  auth: {
    successAuth: 'Success auth',
    errorAuth: 'Error auth',
  },
  init: {
    successLoadOptions: 'Success load roles options',
    errorLoadOptions: 'Error load roles options',

    successInfoPageData: 'Success load info page data',
    errorLoadInfoPageData: 'Error load info page data',
  },
}
