import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'
import { IpTypographyProps } from '../../../types/ui/IpTypography.types'

export function IpText(props: IpTypographyProps & { children: ReactNode }) {
  const { variant, align, paragraph, classes, children } = props
  return (
    <Typography
      variant={variant}
      align={align}
      paragraph={paragraph}
      classes={{ root: classes }}
    >
      {children}
    </Typography>
  )
}
