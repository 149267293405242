import { getSearchCommunitySchema } from './searchCommunity'
import { getSearchSchema } from './search'
import { getCommunityNameSchema } from './communityName'
import { getFirstNameSchema } from './firstName'
import { getLastNameSchema } from './lastName'
import { getUserIdSchema } from './userIdNumber'
import { getJobTitleSchema } from './userJobTitle'
import { getPhoneNumberSchema } from './phoneNumber'
import { getSchoolIdSchema } from './schoolId'
import { getSchoolNameSchema } from './schoolName'
import { getUniqueCommunityIdSchema } from './uniqueCommunityId'
import { getParentStructureSchema } from './parentStructure'

export const getSchemas = (requiredFields: string[]) => ({
  schoolName: getSchoolNameSchema(requiredFields.includes('schoolName')),
  schoolId: getSchoolIdSchema(requiredFields.includes('schoolId')),
  communityName: getCommunityNameSchema(
    requiredFields.includes('communityName')
  ),
  uniqueCommunityId: getUniqueCommunityIdSchema(
    requiredFields.includes('uniqueCommunityId')
  ),
  parentStructure: getParentStructureSchema(
    requiredFields.includes('parentStructure')
  ),

  lastName: getLastNameSchema(requiredFields.includes('lastName')),
  firstName: getFirstNameSchema(requiredFields.includes('firstName')),
  userIdNumber: getUserIdSchema(requiredFields.includes('userIdNumber')),
  userRole: getUserIdSchema(requiredFields.includes('userRole')),
  userJobTitle: getJobTitleSchema(requiredFields.includes('userJobTitle')),
  userPhoneNumber: getPhoneNumberSchema(
    requiredFields.includes('userPhoneNumber')
  ),
  search: getSearchSchema(requiredFields.includes('search')),
  community: getSearchCommunitySchema(requiredFields.includes('community')),
})
