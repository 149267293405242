import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { defaultPageSize } from '../../constants/forms/users'
import { FilterFields } from '../../types/filter.types'
import {
  CreatedUserResponseType,
  UsersSliceType,
  UsersTransformResponseType,
} from '../../types/user.types'

import {
  deleteUser,
  fetchUsers,
  createUser,
  fetchUserById,
  editUserData,
} from '../thunk/usersThunk'

const initialState: UsersSliceType = {
  data: { currentPage: [], skipToken: '', pageSize: 5 },
  currentUser: {
    id: '',
    firstName: '',
    lastName: '',
    displayName: '',
    userPrincipalName: '',
    uniqueUserId: '',
    userRole: '',
    jobTitle: '',
    companyName: '',
    phoneNumber: '',
    mfaEnabled: false,
  },
  filter: {
    search: '',
    filterBy: '',
    filterValue: '',
    orderBy: 'userPrincipalName',
    orderDir: 'asc',
    pageSize: defaultPageSize,
  },
  isLoading: false,
  error: '',
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadMoreUsers: (
      state,
      { payload }: PayloadAction<UsersTransformResponseType>
    ) => {
      state.data.currentPage = [
        ...state.data.currentPage,
        ...payload.currentPage,
      ]
      state.data.skipToken = payload.skipToken
      state.isLoading = false
    },
    firstUsersLoad: (
      state,
      { payload }: PayloadAction<UsersTransformResponseType>
    ) => {
      state.data = payload
      state.isLoading = false
    },
    deleteUserById: (state, { payload }: PayloadAction<string>) => {
      state.data.currentPage = state.data.currentPage.filter(
        (item) => item.id !== payload
      )
      state.isLoading = false
    },
    updateUsersFilter: (state, { payload }: PayloadAction<FilterFields>) => {
      state.filter = payload
    },
    fetchUser: (state, { payload }: PayloadAction<CreatedUserResponseType>) => {
      state.currentUser = payload
    },
    setUserUpdatedPhone: (state, { payload }: PayloadAction<string>) => {
      state.currentUser.phoneNumber = payload
    },
  },
  extraReducers: {
    [fetchUsers.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchUsers.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [deleteUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [deleteUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [createUser.pending.type]: (state) => {
      state.isLoading = true
    },
    [createUser.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [createUser.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchUserById.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchUserById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchUserById.fulfilled.type]: (state) => {
      state.isLoading = false
    },
    [editUserData.pending.type]: (state) => {
      state.isLoading = true
    },
    [editUserData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [editUserData.fulfilled.type]: (state) => {
      state.isLoading = false
    },
  },
})
export const {
  loadMoreUsers,
  firstUsersLoad,
  deleteUserById,
  updateUsersFilter,
  fetchUser,
  setUserUpdatedPhone,
} = usersSlice.actions

export default usersSlice.reducer
