import * as React from 'react'
import { Link, useLocation, NavLink } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { navbarItems } from '../../constants/navigation'

import logo from '../../assets/images/mon_logo_black-1.png'
import logo2 from '../../assets/images/science-logo.png'
import { useAppSelector } from '../../redux/store'
import { getPermissions } from '../../redux/selectors'
import './index.scss'

const NavBar = () => {
  const { pathname } = useLocation()
  const usersPermissions = useAppSelector(getPermissions)
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const getIsActiveMenuItem = (locationPath: string) =>
    locationPath === pathname

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navbarItems.map(({ path, name, permission }, index) => {
                if (permission && !usersPermissions.includes(permission)) {
                  return null
                }
                return (
                  <MenuItem
                    key={index}
                    onClick={handleCloseNavMenu}
                    selected={getIsActiveMenuItem(path)}
                  >
                    <Link to={path}>
                      <Typography textAlign="center">{name}</Typography>
                    </Link>
                  </MenuItem>
                )
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {navbarItems.map(({ path, name, permission }, index) => {
              if (permission && !usersPermissions.includes(permission)) {
                return null
              }
              return (
                <NavLink key={index} to={path}>
                  {({ isActive }) => (
                    <Button
                      sx={{
                        my: 2,
                        color: isActive ? '#2E4DFF' : '#080A03',
                        display: 'block',
                        mr: 2,
                      }}
                    >
                      {name}
                    </Button>
                  )}
                </NavLink>
              )
            })}
          </Box>
          <img src={logo2} className="navbar-logo2" />
          <img src={logo} className="navbar-logo" />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default NavBar
