import React, { FC, Fragment } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { permissionResourcesKeys } from '../constants/common'
import { InfoPage } from '../pages/InfoPage'
import { CommunityList } from '../pages/CommunityList'
import { CreateCommunity } from '../pages/CreateCommunity'
import { CreateSchool } from '../pages/CreateSchool'
import { CreateUser } from '../pages/CreateUser'
import { SchoolProfile } from '../pages/SchoolProfile'
import { SchoolsList } from '../pages/SchoolsList'
import { SuccessCreateUser } from '../pages/SuccesCreateUser'
import { SuccessCreateCommunity } from '../pages/SuccessCreateCommunity'
import { SuccessCreateSchool } from '../pages/SuccessCreateSchool'
import { UserProfile } from '../pages/UserProfile'
import { UsersList } from '../pages/UsersList'
import { getPermissions } from '../redux/selectors'
import { useAppSelector } from '../redux/store'
import { CommunityProfile } from '../pages/CommunityProfile'

const {
  schoolsList,
  schoolsCreate,
  schoolsView,
  schoolsEdit,
  schoolsSubordinate,
  communitiesList,
  communitiesCreate,
  communitiesView,
  communitiesEdit,
  usersList: usersListPermission,
  usersCreate,
  usersView,
  usersEdit,
  usersDelete,
  ITadminEdit,
  schoolsItadminEdit,
  communitiesItadminEdit,
} = permissionResourcesKeys

type OperationTypes = {
  basePermission:
    | typeof schoolsList
    | typeof schoolsCreate
    | typeof schoolsView
    | typeof schoolsEdit
    | typeof schoolsSubordinate
    | typeof communitiesList
    | typeof communitiesCreate
    | typeof communitiesView
    | typeof communitiesEdit
    | typeof usersListPermission
    | typeof usersCreate
    | typeof usersView
    | typeof usersEdit
    | typeof usersDelete
    | typeof ITadminEdit
    | typeof schoolsItadminEdit
    | typeof communitiesItadminEdit
  nestedOperations?: Array<string>
}

type RouteItem = {
  path: string
  Element: FC
  routePermissions?: OperationTypes
  allSourcesAllowed?: boolean
}

type RoutesConfigurationType = {
  [route: string]: RouteItem
}

type RenderPageTypes = {
  Element: FC
}

export const routesConfiguration: RoutesConfigurationType = {
  createUser: {
    path: '/create-user',
    Element: CreateUser,
    routePermissions: { basePermission: usersCreate },
  },
  usersList: {
    path: '/users-list',
    Element: UsersList,
    routePermissions: {
      basePermission: usersListPermission,
      nestedOperations: [usersView, usersEdit, usersDelete, ITadminEdit],
    },
  },
  userProfile: {
    path: '/user-profile',
    Element: UserProfile,
    routePermissions: {
      basePermission: usersView,
      nestedOperations: [usersEdit, ITadminEdit],
    },
  },
  createSchool: {
    path: '/create-school',
    Element: CreateSchool,
    routePermissions: { basePermission: schoolsCreate },
  },
  schoolList: {
    path: '/schools-list',
    Element: SchoolsList,
    routePermissions: {
      basePermission: schoolsList,
      nestedOperations: [
        schoolsView,
        schoolsEdit,
        schoolsSubordinate,
        schoolsItadminEdit,
      ],
    },
  },
  schoolProfile: {
    path: '/school-profile',
    Element: SchoolProfile,
    routePermissions: {
      basePermission: schoolsView,
      nestedOperations: [schoolsEdit, schoolsItadminEdit],
    },
  },
  createCommunity: {
    path: '/create-community',
    Element: CreateCommunity,
    routePermissions: { basePermission: communitiesCreate },
  },
  communityList: {
    path: '/community-list',
    Element: CommunityList,
    routePermissions: {
      basePermission: communitiesList,
      nestedOperations: [
        communitiesEdit,
        communitiesCreate,
        communitiesView,
        communitiesItadminEdit,
      ],
    },
  },
  communityProfile: {
    path: '/community-profile',
    Element: CommunityProfile,
    routePermissions: {
      basePermission: communitiesView,
      nestedOperations: [communitiesEdit, communitiesItadminEdit],
    },
  },
  successCreateSchool: {
    path: '/success-create-school',
    Element: SuccessCreateSchool,
    routePermissions: { basePermission: schoolsCreate },
  },
  successCreateCommunity: {
    path: '/success-create-community',
    Element: SuccessCreateCommunity,
    routePermissions: { basePermission: communitiesCreate },
  },
  successCreateUser: {
    path: '/success-create-user',
    Element: SuccessCreateUser,
    routePermissions: { basePermission: usersCreate },
  },
  infoPage: {
    path: '/info-page',
    Element: InfoPage,
    allSourcesAllowed: true,
  },
}

function renderPage({ Element }: RenderPageTypes) {
  return (
    <PageLayout>
      <Element />
    </PageLayout>
  )
}

function filterRoutesByPermissions(
  userPermissions: Array<string>,
  path: string,
  Element: FC,
  routePermissions?: OperationTypes,
  allSourcesAllowed?: boolean
) {
  if (allSourcesAllowed) {
    return <Route path={path} element={renderPage({ Element })} />
  }
  if (routePermissions) {
    const isNested = routePermissions.nestedOperations?.find((item) =>
      userPermissions.includes(item)
    )
    if (userPermissions.includes(routePermissions.basePermission) || isNested) {
      return <Route path={path} element={renderPage({ Element })} />
    }
  }
  return null
}

export const Routing = () => {
  const userPermissions: Array<string> = useAppSelector(getPermissions)
  const wrongElementPath = userPermissions.includes(usersListPermission)
    ? routesConfiguration.usersList.path
    : routesConfiguration.infoPage.path
  return (
    <BrowserRouter>
      <Routes>
        {Object.entries(routesConfiguration).map(
          ([key, { path, Element, routePermissions, allSourcesAllowed }]) => {
            return (
              <Fragment key={key}>
                {filterRoutesByPermissions(
                  userPermissions,
                  path,
                  Element,
                  routePermissions,
                  allSourcesAllowed
                )}
              </Fragment>
            )
          }
        )}
        <Route path="*" element={<Navigate replace to={wrongElementPath} />} />
      </Routes>
    </BrowserRouter>
  )
}
