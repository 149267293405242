import React from 'react'
import { IpText } from '../../components/ui/IpText'
import { BackBtn } from '../../components/BackBtn'
import { CreateSchoolForm } from '../../components/CreateSchoolForm'

export const CreateSchool: React.FC = () => {
  return (
    <>
      <BackBtn />
      <IpText variant="h4">Створити новий навчальний заклад</IpText>
      <CreateSchoolForm />
    </>
  )
}
