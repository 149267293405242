import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DialogActions, AlertColor } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../redux/store'

import { IpText } from '../../components/ui/IpText'
import { IpButton } from '../../components/ui/IpButton'
import { CustomTable } from '../../components/CustomTable'
import { LoaderPortal } from '../../components/Loader/LoaderPortal'
import { CustomModal } from '../../components/CustomModal'
import { FilterForm } from '../../components/FilterForm'

import { routesConfiguration } from '../../routing'

import { UsersListTableData } from '../../types/table.types'
import { FilterFields } from '../../types/filter.types'
import { filterUsersList, userRoles } from '../../constants/forms/users'

import {
  deleteUser,
  fetchUserById,
  fetchUsers,
  resetPassword,
} from '../../redux/thunk/usersThunk'
import { updateUsersFilter } from '../../redux/slices/usersSlice'
import { usePermissions } from '../../hooks/usePermissions'
import { alertTypes, permissionResourcesKeys } from '../../constants/common'
import { getUserFilter } from '../../redux/selectors'
import { addAlert } from '../../redux/slices/alertsList'
import { useTracking } from '../../hooks/tracking/useTracking'
import './index.scss'
import { CreatedUserResponseType } from '../../types/user.types'
import { trackEvents } from '../../constants/appInsights'
import { CatchErrorType } from '../../types/redux'

const { usersDelete, usersEdit, usersView, usersCreate, ITadminEdit } =
  permissionResourcesKeys

const {
  errorReseteUserPassword,
  successReseteUserPassword,
  errorLoadUserProfile,
  successLoadUserProfile,
  errorLoadUsersList,
  successLoadUsersList,
  errorDeleteUser,
  successDeleteUser,
} = trackEvents.users

export const UsersList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getIsOperationAllowed } = usePermissions()
  const { trackEvent } = useTracking()
  const { pageSize } = useAppSelector(getUserFilter)

  const [isNextBtnDisable, setNextBtnDisable] = useState(false)
  const [isDeleteUserPopupOpen, setDeleteUserPopupOpen] = useState(false)
  const [isResetPassPopupOpen, setResetPassPopupOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState({} as UsersListTableData)

  const orderByFields = ['displayName', 'userPrincipalName']

  const {
    data: { currentPage, skipToken },
    filter,
    isLoading,
  } = useAppSelector((state) => state.users)

  const transformedUsers = useMemo(
    () =>
      currentPage.map((item) => ({
        id: item.id,
        userIdNumber: item.uniqueUserId,
        firstName: item.firstName,
        lastName: item.lastName,
        displayName: item.displayName,
        userPrincipalName: item.userPrincipalName,
        companyName: item.companyName || '',
        userJobTitle: item.jobTitle || '',
        userRole:
          userRoles.find((role) => role.label === item.userRole)?.value || '',
        mfaStatus: !!item.mfaEnabled,
      })),
    [currentPage]
  )

  useMemo(() => {
    if (skipToken?.length) {
      setNextBtnDisable(false)
    } else {
      setNextBtnDisable(true)
    }
  }, [skipToken])

  const openUserProfile = (row: UsersListTableData) => {
    const { id } = row
    const onSuccess = (user: CreatedUserResponseType) => {
      trackEvent({ name: successLoadUserProfile, payload: user })
      navigate(routesConfiguration.userProfile.path)
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorLoadUserProfile, payload: error })
    }
    dispatch(fetchUserById({ id, onSuccess, onError }))
  }

  const onDeleteUserIconClick = (user: UsersListTableData) => {
    setDeleteUserPopupOpen(true)
    setCurrentUser(user)
  }

  const onDeleteUser = () => {
    const onSuccess = () => {
      trackEvent({ name: successDeleteUser, payload: currentUser })
      dispatch(
        addAlert({
          id: 'userDeleted',
          type: alertTypes.success as AlertColor,
          text: 'Вітаємо! Користувача успішно видалено!',
        })
      )
    }

    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorDeleteUser, payload: error })
    }

    setDeleteUserPopupOpen(false)
    dispatch(deleteUser({ id: currentUser.id, onSuccess, onError }))
  }

  const onChangePassClick = (user: UsersListTableData) => {
    setCurrentUser(user)
    setResetPassPopupOpen(true)
  }

  const onResetPassword = () => {
    const onSuccess = () => {
      trackEvent({ name: successReseteUserPassword, payload: currentUser })
      dispatch(
        addAlert({
          id: 'passwordReset',
          type: alertTypes.success as AlertColor,
          text: 'Вітаємо! Пароль успішно скинуто',
        })
      )
    }
    const onError = (error: CatchErrorType) => {
      trackEvent({ name: errorReseteUserPassword, payload: error })
    }
    dispatch(resetPassword({ id: currentUser.id, onError, onSuccess }))
    setResetPassPopupOpen(false)
  }

  const onNextPageClick = () => {
    const payload = {
      params: { ...filter, pageSize },
      onSuccess: onSuccessFetchUsers,
      onError: (error: CatchErrorType) => onErrorFetchUsers(error),
    }
    dispatch(fetchUsers(payload))
  }

  const submitHandler = (newFilters: FilterFields) => {
    dispatch(updateUsersFilter({ ...filter, ...newFilters }))
  }

  const onSortAndFilterHandle = (newFilters: FilterFields) => {
    dispatch(updateUsersFilter({ ...filter, ...newFilters }))
  }

  const onRowsPerPageChanged = (rowsCount: string) => {
    dispatch(updateUsersFilter({ ...filter, pageSize: rowsCount }))
  }

  useEffect(() => {
    const payload = {
      params: { ...filter, pageSize },
      onSuccess: onSuccessFetchUsers,
      onError: (error: CatchErrorType) => onErrorFetchUsers(error),
    }
    dispatch(fetchUsers(payload))
  }, [filter])

  function onSuccessFetchUsers() {
    trackEvent({ name: successLoadUsersList, payload: { ...filter, pageSize } })
  }

  function onErrorFetchUsers(error: CatchErrorType) {
    trackEvent({ name: errorLoadUsersList, payload: error })
  }

  return (
    <>
      <LoaderPortal show={isLoading} />
      <IpText variant="h4">Список користувачів</IpText>

      <FilterForm
        submitHandler={submitHandler}
        list={filterUsersList}
        defaultOrderBy="userPrincipalName"
        orderDir={filter.orderDir || 'asc'}
      />

      <CustomTable
        className="edit-list-form"
        data={transformedUsers}
        type="usersList"
        isPossibleSelectRow={true}
        isPossibleSelectAllRows={true}
        onNextPageClick={onNextPageClick}
        isNextBtnDisable={isNextBtnDisable}
        onSortAndFilter={onSortAndFilterHandle}
        orderByFields={orderByFields}
        onDeleteIconClick={onDeleteUserIconClick}
        onChangePassClick={onChangePassClick}
        onRowClick={openUserProfile}
        onRowsPerPageChanged={onRowsPerPageChanged}
        defaultRowsPerPage={pageSize ? +pageSize : 5}
        defaultOrderBy="userPrincipalName"
        defaultOrderDir="asc"
        allowDelete={getIsOperationAllowed([usersDelete])}
        allowEdit={getIsOperationAllowed([usersEdit, ITadminEdit])}
        allowView={getIsOperationAllowed([usersView, ITadminEdit])}
        allowChangePass={getIsOperationAllowed([usersEdit])}
      />

      <CustomModal
        headerText={`Ви дійсно хочете видалити користувача (${currentUser.firstName} ${currentUser.lastName})?`}
        isOpen={isDeleteUserPopupOpen}
      >
        <DialogActions>
          <IpButton
            onClick={() => setDeleteUserPopupOpen(false)}
            variant="outlined"
            size="small"
          >
            Ні
          </IpButton>
          <IpButton onClick={onDeleteUser} variant="contained" size="small">
            Так
          </IpButton>
        </DialogActions>
      </CustomModal>

      <CustomModal
        headerText={`Ви дійсно хочете скинути пароль користувачу (${currentUser.firstName} ${currentUser.lastName})?`}
        isOpen={isResetPassPopupOpen}
      >
        <DialogActions>
          <IpButton
            onClick={() => setResetPassPopupOpen(false)}
            variant="outlined"
            size="small"
          >
            Ні
          </IpButton>
          <IpButton onClick={onResetPassword} variant="contained" size="small">
            Так
          </IpButton>
        </DialogActions>
      </CustomModal>

      {getIsOperationAllowed([usersCreate]) && (
        <IpButton
          classes="create-user-btn"
          variant="contained"
          size="large"
          onClick={() => navigate('/create-user')}
        >
          Створити користувача
        </IpButton>
      )}
    </>
  )
}
