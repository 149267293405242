const primaryFontFamily = 'innerspace'

export default {
  fontFamily: primaryFontFamily,
  htmlFontSize: 16,
  fontSize: 16,
  h1: {
    fontWeight: 700,
    fontSize: '68px',
    lineHeight: '82px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '58px',
    lineHeight: '79px',
  },
  h3: {
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '58px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '38px',
    lineHeight: '46px',
    margin: '20px 0',
  },
  h5: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '34px',
  },
  h6: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '28px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  body3: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
  },
  body4: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
  },
  button: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'initial',
  },
}
