import { AlertColor } from '@mui/material'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  FetchSchoolsThunkPayloadType,
  CreateSchoolThunkPayloadType,
  CreatedSchoolResponseType,
  EditSchoolParentThunkPayloadType,
  EditSchoolDataThunkPayloadType,
} from '../../types/schools.types'

import { getCommunities } from '../selectors/index'

import {
  apiEditSchoolParent,
  apiEditSchool,
  apiGetSchoolById,
  apiCreateSchool,
  apiGetSchools,
} from '../../services/api'

import { CatchErrorType } from '../../types/redux'

import { RootState } from '../store'
import { getSSOToken } from '../selectors'

import { alertTypes } from '../../constants/common/index'

import { addAlert } from '../slices/alertsList'

import {
  fetchSchool,
  firstSchoolsLoad,
  loadMoreSchools,
  updateSchoolName,
  updateSchoolParent,
} from '../slices/schoolsSlice'
import { prepareErrorText } from '../../utils'

export const fetchSchools = createAsyncThunk<
  void,
  FetchSchoolsThunkPayloadType
>(
  'school/fetch',
  // eslint-disable-next-line consistent-return
  async ({ params, onSuccess, onError }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetSchools({ ssoToken, params })

      if (params.skipToken) {
        thunkAPI.dispatch(loadMoreSchools(data))
      } else {
        thunkAPI.dispatch(firstSchoolsLoad(data))
      }
      onSuccess()
      thunkAPI.dispatch(
        addAlert({
          id: 'schoolsFetchSuccess',
          type: alertTypes.success as AlertColor,
          text: 'Навчальні заклади успішно оновилися',
        })
      )
    } catch (e: unknown) {
      const error = e as CatchErrorType
      onError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'fetchSchoolsError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Не вдалося завантажити навчальні заклади',
        })
      )
      return thunkAPI.rejectWithValue(
        'Не вдалося завантажити навчальні заклади'
      )
    }
  }
)

type FetchSchoolByIdType = {
  id: string
  onSuccess?: (data: CreatedSchoolResponseType) => void
  onError?: (error: CatchErrorType) => void
}

export const fetchSchoolById = createAsyncThunk<void, FetchSchoolByIdType>(
  'school/fetchSchool',
  // eslint-disable-next-line consistent-return
  async (payload, thunkAPI) => {
    const { id, onSuccess, onError } = payload
    const store = thunkAPI.getState() as RootState
    const ssoToken = getSSOToken(store)
    try {
      const data = await apiGetSchoolById({ ssoToken, id })
      thunkAPI.dispatch(fetchSchool(data))
      if (onSuccess) onSuccess(data)
    } catch (e) {
      const error = e as CatchErrorType
      if (onError) onError(error)

      thunkAPI.dispatch(
        addAlert({
          id: 'fetchSchoolError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході відкриття профіля навчального заклад',
        })
      )

      return thunkAPI.rejectWithValue(
        'Не вдалося завантажити навчальний заклад'
      )
    }
  }
)

export const createSchool = createAsyncThunk<
  void,
  CreateSchoolThunkPayloadType
>(
  'schools/create',
  // eslint-disable-next-line consistent-return
  async ({ body, onSuccess, onError }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiCreateSchool({ ssoToken, body })
      onSuccess()
      thunkAPI.dispatch(
        addAlert({
          id: 'schoolCreated',
          type: alertTypes.success as AlertColor,
          text: 'Вітаємо! Новий навчальний заклад створено!',
        })
      )
    } catch (e: unknown) {
      const error = e as CatchErrorType
      onError(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'createSchoolError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході створення нового навчального закладу',
        })
      )
      return thunkAPI.rejectWithValue('Не вдалося створити навчальний заклад')
    }
  }
)

export const editSchoolData = createAsyncThunk<
  void,
  EditSchoolDataThunkPayloadType
>(
  'schools/editSchoolData',
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    const { body, onSuccessEditData, onErrorEditData } = data
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiEditSchool({ ssoToken, body })
      if (onSuccessEditData) onSuccessEditData()
      thunkAPI.dispatch(updateSchoolName(body.name))
    } catch (e: unknown) {
      const error = e as CatchErrorType
      onErrorEditData(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'editSchoolDataError',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході оновлення даних навчального закладу',
        })
      )
      return thunkAPI.rejectWithValue(
        'Не вдалося оновити дані про навчальний заклад'
      )
    }
  }
)

export const editSchoolParent = createAsyncThunk<
  void,
  EditSchoolParentThunkPayloadType
>(
  'schools/editSchoolParentData',
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    const { body, onSuccessEditParentData, onErrorEditParentData } = data
    try {
      const store = thunkAPI.getState() as RootState
      const ssoToken = getSSOToken(store)
      await apiEditSchoolParent({ ssoToken, body })
      if (onSuccessEditParentData) onSuccessEditParentData()
      const {
        searchCommunitiesResult: { communities },
      } = getCommunities(store)
      const communityName =
        communities.find((item) => item.uniqueCommunityId === body.communityId)
          ?.name || ''
      thunkAPI.dispatch(
        updateSchoolParent({
          communityId: body.communityId,
          name: communityName,
        })
      )
    } catch (e: unknown) {
      const error = e as CatchErrorType
      onErrorEditParentData(error)
      thunkAPI.dispatch(
        addAlert({
          id: 'editSchoolParentData',
          type: alertTypes.error as AlertColor,
          text: error?.response?.data?.length
            ? prepareErrorText(error.response.data)
            : 'Виникла помилка в ході оновлення даних підпорядкованості навчального закладу',
        })
      )
      return thunkAPI.rejectWithValue(
        'Не вдалося оновити дані про підпорядкованості навчального закладу'
      )
    }
  }
)
