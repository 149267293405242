import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { SESSION_ID_STORAGE_KEY } from '../../constants/common/index'

export const useTracking = () => {
  const appInsights = useAppInsightsContext()

  interface Type<P> {
    name: string
    payload: P
  }

  function trackEvent<P>({ name, payload }: Type<P>) {
    appInsights.trackEvent(
      { name },
      {
        payload,
        sessionId: sessionStorage.getItem(SESSION_ID_STORAGE_KEY) || '',
      }
    )
  }

  return {
    trackEvent,
  }
}
